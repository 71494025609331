import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';

const SerialItem = ({ serialList }) => {

    const todoItems = serialList.map((selectedItem) =>

        <div className="col-10 col-sm-5 col-md-11 col-lg-8 offset-1 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-0 item p-2">
            <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4 offset-sm-0"><a href="#"><img className="img-fluid border rounded" src={selectedItem.thumbnailUrl} /></a></div>
                <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-7 offset-lg-0 text-left">
                    <h5 className="text-left name">{selectedItem.title}</h5>
                    <p className="text-left description">{selectedItem.description}</p><strong className="ybx-pill">Epizoda {selectedItem.orderId}</strong></div>
            </div>
        </div>


    );

    return todoItems
};

export default SerialItem;