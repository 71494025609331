import React from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import { PersonFill, PlayCircle } from 'react-bootstrap-icons';
import Loader from "react-loader-spinner";

class MobileSearchField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            query: '',
            results: {},
            loading: false,
            message: '',
            totalResults: 0,
            totalPages: 0,
            currentPageNo: 0,
        };

        this.cancel = '';
    }


    /**
     * Get the Total Pages count.
     *
     * @param total
     * @param denominator Count of results per page
     * @return {number}
     */
    getPageCount = (total, denominator) => {
        const divisible = 0 === total % denominator;
        const valueToBeAdded = divisible ? 0 : 1;
        return Math.floor(total / denominator) + valueToBeAdded;
    };

    /**
     * Fetch the search results and update the state with the result.
     * Also cancels the previous query before making the new one.
     *
     * @param {int} updatedPageNo Updated Page No.
     * @param {String} query Search Query.
     *
     */
    fetchSearchResults = (updatedPageNo = '', query) => {

        if (query.length < 4) { return }
        const searchUrl = `https://ybx-api.taptech.rs/content/search?q=${query}`;

        if (this.cancel) {
            this.cancel.cancel();
        }

        this.cancel = axios.CancelToken.source();

        axios.get(searchUrl, {
            cancelToken: this.cancel.token
        })
            .then(res => {
                const total = res.data.data.results.length;
                const totalPagesCount = this.getPageCount(total, 20);
                const resultNotFoundMsg = !res.data.data.results.length
                    ? 'Ništa nije pronađeno :( pokušajte ponovo.'
                    : '';
                this.setState({
                    results: res.data.data.results,
                    message: resultNotFoundMsg,
                    totalResults: total,
                    totalPages: totalPagesCount,
                    currentPageNo: updatedPageNo,
                    loading: false
                })
            })
            .catch(error => {
                if (axios.isCancel(error) == false && error) {
                    this.setState({
                        loading: false,
                        message: 'Došlo je do greške. Pokušajte kasnije.'
                    })
                }
            })
    };

    handleOnInputChange = (event) => {
        const query = event.target.value;

        if (!query || query.length < 4) {
            this.setState({ query, loading: false, results: {}, message: '', totalPages: 0, totalResults: 0 });
        } else {
            console.log("PALI LOADER. QUERY JE:", query);
            this.setState({ query, loading: true, message: '' }, () => {
                this.fetchSearchResults(1, query);
            });
        }
    };


    handleOnSelect = (item) => {
        if (item.data_type == "V") {
            this.props.history.push("/video/" + item.type_id);
            ReactGA.event({
                category: 'Search',
                action: 'videoClick',
                label: "/video/" + item.type_id
            });
        } else {
            this.props.history.push("/artist/" + item.type_id);
            ReactGA.event({
                category: 'Search',
                action: 'artistClick',
                label: "/artist/" + item.type_id
            });
        }
        this.setState({ query: '', loading: false, results: {}, message: '', totalPages: 0, totalResults: 0 });
    };

    renderSearchResults = () => {
        const { results } = this.state;
        if (Object.keys(results).length && results.length) {
            return (
                <div id="myInputautocomplete-list" className="autocomplete-items-mobile">

                    {results.map(result => {
                        if (result.data_type == "V") {
                            //video
                            return (
                                <div key={result.type_id} className="autocomplete-v-item" onClick={() => this.handleOnSelect(result)}>
                                    <div className="row">
                                        <div className="col-1">
                                            <PlayCircle className="ybxred" size={28} />
                                        </div>
                                        <div className="col-10">
                                            <div className="row">
                                                <div className="col text-white">{result.text.substr(0, 40)}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col ybxSubtitle-silver">{result.creators}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                        } else {
                            //artist
                            return (<div key={result.type_id} className="autocomplete-v-item" onClick={() => this.handleOnSelect(result)}>
                                <div className="row">
                                    <div className="col-1">
                                        <PersonFill className="ybxred" size={28} />
                                    </div>
                                    <div className="col-10">
                                        <div className="row">
                                            <div className="col text-white">{result.text}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col ybxSubtitle-silver ybxred">Izvođač</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        }

                    })}
                </div>
            )
        }
    };

    loaderView = (isShow) => {
        return (
            <div id="myInputautocomplete-list" className={`'autocomplete-items-mobile' ${isShow}`}>
                {/* <img src="/assets/img/loader-search.gif" className={`search-loading ${isShow}`} alt="loader" /> */}
                <div className="p-2d"></div>
                <Loader type="TailSpin" color="#E81E4D" height={32} width={32} />
                <div className="p-2"></div>
            </div>
        )
    }

    messageView = (message) => {
        return (
            <div id="myInputautocomplete-list" className="autocomplete-items-mobile">
                {message && <p className="message">{message}</p>}
            </div>
        )
    }


    render() {
        const { query, loading, message, currentPageNo, totalPages } = this.state;
        console.log("loading je:", loading);
        return (
            <div>
                <input type="search" className="search-header-field"
                    name="query"
                    value={query}
                    id="search-input"
                    placeholder="Pretraži YouBox"
                    onChange={this.handleOnInputChange}
                />
                {/*	Error Message*/}
                {this.messageView(message)}
                {/*	Loader*/}
                {this.loaderView(loading ? 'show' : 'hide')}
                {/*	Result*/}
                {this.renderSearchResults()}
            </div>
        )
    }
}

export default MobileSearchField