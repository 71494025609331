import React, { useState, useEffect } from 'react';
import Loader from "react-loader-spinner";
import { Row, Col, Container } from 'react-bootstrap';

const LoadingYbx = () => {

    return (
        <div className="container">
            <section className="generalHead">
                <Container style={{'paddingTop':'50px'}}>
                    <Row className="justify-content-md-center">
                        <Col md="auto"><Loader type="Grid" color="#E81E4D" height={58} width={58} /></Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto text-white">sačekajte</Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
};

export default LoadingYbx;
