import axios from 'axios';
import LoginUserService from '../components/auth/useToken';

// const loggedIn = LoginUserService.isSignedIn();


export default axios.create({
  baseURL: 'https://ybx-api.taptech.rs',
  // baseURL: 'https://ybx-app-dev.taptech.rs',
  headers: getHeaders(),  
});

function getHeaders() {
  let token = LoginUserService.getUserToken();
  if (token) {
    return {
      Authorization:     
        'Bearer ' + LoginUserService.getUserToken()
    }
  }else{
    
    return {}
  }
}