import React from 'react';

import ybxClient from '../../apis/ybxClient';
import OwlCarousel from "react-owl-carousel";
import FavoriteSongs from './FavoriteSongs';
import LayoutFrame from '../feed/Layout';
import FavoriteArtist from './FavoriteArtist';
import { Row } from 'react-bootstrap';
import LoadingYbx from '../general/Loading';
import PublicFooter from '../general/PublicFooter';
import EmptyPlaceholder from './EmptyPlaceholder';
import LoadingErrorInfoComponent from '../general/LoadingErrorInfoComponent';

class FavoriteVideosPage extends React.Component {

    state = { myFavVideos: null, myFavArtists: null };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        const response = await ybxClient.get('content/videos/favorites').catch((e) => {
            console.error("Greska pri ucitavanju omiljenih videa; Error = ", e);
            this.setState({ loadingErrorOccured: true });
        });
        const myFavResponse = await ybxClient.get('content/artists/favorites').catch((e) => {
            console.error("Greska pri ucitavanju omiljenih artista; Error = ", e);
            this.setState({ loadingErrorOccured: true });
        });

        let allVideoGroups;

        if (response && response.data && response.data.data) {
            allVideoGroups = response.data.data;
        } else {
            allVideoGroups = [];
        }

        let favArtistTemp;
        if (myFavResponse && myFavResponse.data && myFavResponse.data.data) {
            favArtistTemp = myFavResponse.data.data;
        } else {
            favArtistTemp = [];
        }

        this.setState({ myFavVideos: allVideoGroups, myFavArtists: favArtistTemp });

    }



    render() {

        const hasVideos = (this.state.myFavVideos == null || this.state.myFavVideos.length < 1) ? false : true;
        const hasArtists = (this.state.myFavArtists == null || this.state.myFavArtists.length) < 1 ? false : true;

        if (this.state.loadingErrorOccured) {
            return <LayoutFrame fixedBg={true}><LoadingErrorInfoComponent /></LayoutFrame>
        } else if (this.state.myFavVideos == null) {
            return <LayoutFrame fixedBg={true}><main className="page"> <section className="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
        }

        if (hasVideos == false && hasArtists == false) {
            return <LayoutFrame fixedBg={true}>
                <div className="container">
                    <section className="generalHead">
                        <EmptyPlaceholder />
                    </section>
                </div>
            </LayoutFrame>
        }

        return <LayoutFrame fixedBg={true}>
            
            <main className="page">
                <section className="clean-block features">
                    <div className="container text-white">
                        <h2 className="text-left ybx-page-title">Moji omiljeni snimci</h2>
                    </div>                    
                    
                    <div className="container">
                        <div className="row justify-content-center">
                            <FavoriteSongs favoriteArray={this.state.myFavVideos} />
                        </div>
                    </div>

                </section>
            </main>

            {/* <main className="page">
                <section className="clean-block features">
                    {hasArtists ?
                        <section className="clean-block features">
                            <div className="container text-white">
                                <h2 className="text-left ybx-page-title">Moji omiljeni umetnici</h2>
                            </div>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <FavoriteArtist artistList={this.state.myFavArtists} />
                                </div>
                            </div>
                        </section> : <span></span>
                    }
                    {hasVideos ?
                        <section className="clean-block features">
                            <div className="container text-white">
                                <h2 className="text-left ybx-page-title">Moji omiljeni snimci</h2>
                            </div>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <FavoriteSongs favoriteArray={this.state.myFavVideos} />
                                </div>
                            </div>
                        </section> : <span></span>
                    }
                </section>
            </main> */}

            <PublicFooter hasPadding={true} />
        </LayoutFrame >
    }
};

export default FavoriteVideosPage;