import React from 'react';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import OwlCarousel from "react-owl-carousel";

const HomeVideo = ({ videoList, showArtist }) => {

    const videos = videoList.map((video) =>

        <div className="card ybx-clicl-anim" key={video.video_id} style={{'paddingTop':'20px'}}>
            <Link className="noDecoration" to={'/video/' + video.video_id}>
                <div className="col"><img className="rounded img-fluid" src={'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + video.images[0].url} />
                    <div className="row">
                        <div className="col">
                            <h6 className="ybx-item-title-bold">{video.title}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h6 className="ybx-item-subtitle-silver">{artistList(video.creators)}</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h6 className="ybx-item-subtitle-red">{viewsNumberFormat(video.views)}</h6>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );

    return (
        <div className="card-group">
            <Media queries={{ small: "(max-width: 599px)" }}>
                {matches => (
                    <OwlCarousel className="owl-theme p-4" margin={10} items={matches.small ? 1 : 4} dots={false} nav navText={[
                        "<i className='fa fa-chevron-left'></i>",
                        "<i className='fa fa-chevron-right'></i>"
                    ]}>
                        {videos}
                    </OwlCarousel>
                )}
            </Media>
        </div>
    );

};

function artistList(creators) {
    //var creatorName = "";
    return creators.map((c) => c.nickname);
    // return 
}

function viewsNumberFormat(num) {
    if (num === 1 || num === 21 || num === 31 || num === 41 || num === 51 || num === 61) {
        return num.toLocaleString() + " pregled"
    } else {
        return num.toLocaleString() + " pregleda"
    }
}

export default HomeVideo;