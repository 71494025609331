import React from 'react';
// import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import PublicFooter from '../general/PublicFooter';
import LayoutFrame from '../feed/Layout';

const AboutPage = () => {
    return (
        <LayoutFrame fixedBg={true}>

            <main className="page">
                <section className="clean-block features">
                    <div className="container">
                        <div className="block-heading" style={{'paddingTop': '40px' }}>
                            <h2>YouBox video platforma</h2>
                        </div>
                        <div className="row justify-content-center text-white">
                            <div className="col-md-5 feature-box"><i className="icon-magic-wand icon"></i>
                                <h3>Privilegije za umetnike</h3>
                                <p>Visoka dostupnost, veliki broj korisnika, mogućnost kontakta profesionalnim muzičkim producentima</p>
                            </div>
                            <div className="col-md-5 feature-box"><i className="icon-music-tone-alt icon"></i>
                                <h3>Ekskluziva</h3>
                                <p>Demoi, nastupi uživo, premijere muzičkih spotova.</p>
                            </div>
                            <div className="col-md-5 feature-box"><i className="icon-volume-2 icon"></i>
                                <h3>Zvukovi visokog kvaliteta (HiFi)</h3>
                                <p>Kompresija zvuka visokog kvaliteta.</p>
                            </div>
                            <div className="col-md-5 feature-box"><i className="icon-location-pin icon"></i>
                                <h3>Gde smo dostupni</h3>
                                <p>Naša glavna ciljna publika je na prostoru jugoistočne Evrope</p>
                            </div>
                            <div className="col-md-5 feature-box"><i className="icon-playlist icon"></i>
                                <h3>Članci, Intervjui &amp; Plejliste</h3>
                                <p>Kreiraj plejliste odabirom omiljenih videa i izvođača. Prati najnovije vesti. Uživaj!</p>
                            </div>
                        </div>
                    </div>
                </section>
            </main>


            <PublicFooter hasPadding={false} />
        </LayoutFrame>
    );

};

export default AboutPage;