import React from 'react';
import { Router, Route, Switch } from 'react-router-dom'; // BrowserRouter, useLocation,  Redirect
import PrivateRoute from './auth/PrivateRoute';
import LoginUserService from './auth/useToken';
import history from './history'

import TocPage from './defaultPages/tocPage';
import helpPage from './defaultPages/helpPage';
import AboutPage from './defaultPages/aboutPage';
import DownloadAppPage from './defaultPages/downloadApp';
import Register from './auth/Register';
import Login from './auth/Login';
import Home from './feed/Home';
import ArtistProfile from './artists/ArtistProfile';
import VideoDetails from './videos/VideoDetails';
import ArtistList from './artists/ArtistList';
import VideoList from './videos/VideoList';
import Favorites from './favorites/Favorites'
import PageNotFound from './general/404-page';
import MyProfile from './settings/MyProfile';
import YbxPlus from './ybxPlus/YbxPlus';
import YbxSerial from './ybxSerial/YbxSerial';
import YbxCookies from './defaultPages/YbxCookies';
import ScrollToTop from './general/ScrollToTop';
import ResetPassword from './defaultPages/resetPassword';
import ForgotPassword from './defaultPages/forgotPassword';
import ReactGA from 'react-ga';
import LivestreamDetails from './livestream/LivestreamDetails';

import MobileSearchPage from './search/MobileSearchPage';

const App = () => {
  
  ReactGA.initialize('UA-165266620-01');
  const loggedIn = LoginUserService.isSignedIn();


  return (
    <div>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <Route path="/terms-of-service" exact component={TocPage} />
            <Route path="/about" exact component={AboutPage} />
            <Route path="/download" exact component={DownloadAppPage} />
            <Route path="/help-main" exact component={helpPage} />
            <Route path="/cookies" exact component={YbxCookies} />
            <Route path="/reset-password/:reqid/:token" exact component={ResetPassword} />
            <Route path="/forgot" exact component={ForgotPassword} />

            <PrivateRoute path="/"  exact component={Home} />            
            <Route path="/login" exact component={Login} >
              {loggedIn ? <Home /> : <Login history={history} />}
            </Route>
            <Route path="/register" exact component={Register} >
              {loggedIn ? <Home /> : <Register history={history} />}
            </Route>            
            <PrivateRoute path="/home"  exact component={Home} />          
            <PrivateRoute path="/myprofile"  exact component={MyProfile} />              
            <PrivateRoute path="/artists" exact component={ArtistList} />
            <PrivateRoute path="/artist/:artistId" exact component={ArtistProfile} />
            <PrivateRoute path="/videos" exact component={VideoList} />
            <PrivateRoute path="/video/:videoId" exact component={VideoDetails} />            
            <PrivateRoute path="/favorites" exact component={Favorites} />
            <PrivateRoute path="/plus" exact component={YbxPlus} />
            <PrivateRoute path="/serial" exact component={YbxSerial} />
            <PrivateRoute path="/livestream/:videoId" exact component={LivestreamDetails} history={history}/>

            <PrivateRoute path="/mSearch" exact component={MobileSearchPage} />
            
            <Route component={PageNotFound} />            
            
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
};

export default App;


