import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
// import { Card, Button, OverlayTrigger, Row, Col } from 'react-bootstrap';
import { HeartFill } from 'react-bootstrap-icons'; // Heart

const FavoriteArtist = ({ artistList }) => {

    function artistPhoto(photoArray) {
        if (photoArray.length < 1) {
            return "/assets/missing-300x300.png";
        } else if (photoArray.length === 3) {
            return 'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + photoArray[2].url;
        } else {
            return 'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + photoArray[0].url;
        }

    }

    const artistItems = artistList.map((artist) =>

        <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" style={{'padding-bottom':'20px'}} key={artist.artist_id}>
            <Link to={'/artist/' + artist.artist_id}>
                <div className="row">
                    <div className="col">
                        <div className="card"><img className="rounded img-fluid card-img-top w-100 d-block img-artist-cover" src={artistPhoto(artist.images)} /></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="ybx-item-title-bold">{artist.nickname}</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col"><button className="btn btn-primary btn-sm chat-btn-like" type="button"><i className="fa fa-bookmark"></i>Ukloni</button></div>
                </div>
            </Link>
        </div>
        // <Link to={'/artist/'+artist.artist_id} key={artist.artist_id}>
        //     <div className="card text-white card-has-bg click-col" >
        //         <img src={artistPhoto(artist.images)} />
        //         <div className="card-img-overlay d-flex flex-column">
        //             <div className="card-body">
        //             </div>
        //             <div className="card-footer">
        //                 <div className="media">
        //                     <div className="media-body">
        //                         <h4 className="my-0 text-white d-block">{artist.nickname}</h4>
        //                         <small>
        //                             <Button variant="secondary" size="sm" onClick={() => this.addToFavorite('remove_video')}>
        //                                 <HeartFill className="ybxred" size={20} /> Ukloni
        //                             </Button>
        //                         </small>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </Link>


    );

    return artistItems
};


function VideoNumberFormat(num) {
    if (num === 1 || num === 21 || num === 31 || num === 41 || num === 51 || num === 61) {
        return num + " video"
    } else {
        return num + " videa"
    }
}

export default FavoriteArtist;