import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ArtistItem = ({ artistList }) => {

    const artistItems = artistList.map((artist) =>
    
    <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" style={{'padding-bottom':'20px'}} key={artist.user_id}>
            <Link to={'/artist/' + artist.artist_id}>
                <div className="row">
                    <div className="col">
                        <div className="card clean-card"><img className="rounded img-fluid card-img-top w-100 d-block img-artist-cover" src={'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + artist.images[2].url} /></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="ybx-item-title-bold">{artist.nickname}</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="ybx-item-subtitle-silver">{VideoNumberFormat(artist.number_of_videos)}</h6>
                    </div>
                </div>                
            </Link>
        </div> 
    );

    return artistItems
};


function VideoNumberFormat(num) {
    if (num === 1 || num === 21 || num === 31 || num === 41 || num === 51 || num === 61) {
        return num + " video"
    } else {
        return num + " videa"
    }
}

export default ArtistItem;