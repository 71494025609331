import React from 'react';
import LayoutFrame from '../feed/Layout';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

class ResetPassword extends React.Component {


    state = { reqId: null, token: null };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {

        const reqId = this.props.match.params.reqid;
        const token = this.props.match.params.token;
        this.setState({ reqId: reqId, token: token, resetSuccess: false });
    }

    handleClick = async (values, { setErrors }) => {

        var bodyFormData = new FormData();

        bodyFormData.append('requestId', this.state.reqId);
        bodyFormData.append('password', values.pwd);
        bodyFormData.append('password_confirmation', values.pwdr);
        bodyFormData.append('token', this.state.token);

        const response = await axios({
            method: "post",
            url: "https://ybx-api.taptech.rs/unp/reset-password",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).catch(function (error) {
            if (error.response) {
                return Promise.resolve({ error });
            }
        }).then((response) => {
            if (response.error != null) {
                setErrors({ pwdr: response.error.response.data.message });
            } else {
                localStorage.removeItem("ybxState")
                this.setState({ resetSuccess: true });
            }
        });
    }


    render() {
        // if (this.state.userData == null) {
        //     return <LayoutFrame><LoadingYbx /></LayoutFrame>
        // }


        if (this.state.resetSuccess) {
            return (
                <LayoutFrame fixedBg={true}>
                    <main className="page">
                <section className="clean-block features text-white" style={{ 'paddingTop': '100px' }}>
                                    
                            {/* <section className="generalHead"> */}
                            <div className="container">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12">
                                        <div className="error-message text-center">
                                            <div className='about-icon'>
                                                <img src='/assets/img/modal-check.png' alt="" />
                                            </div>
                                            <h1 className='text-white'>Čestitamo!</h1>
                                            <p className='text-white mb-1'>Uspešno promenjena lozinka.</p>
                                            <Link to="/login" className="btn btn-secondary text-uppercase"><img className="mr-2" src="assets/img/arrow-left.png" alt="" />Povratak na početnu</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                    {/* </div> */}
                </LayoutFrame>
            );
        }



        return <LayoutFrame fixedBg={true}>
            <main className="page">
                <section className="clean-block features text-white">
                    <div className="container">
                        <section className="generalHead">
                            <div className="block-heading" style={{ 'paddingTop': '100px' }}>
                                <h2 style={{ 'color': 'white' }}>Reset lozinke</h2>
                            </div>

                            <Row className="justify-content-md-center pt-4">
                                <Col xs={12} md={5} lg={5} >
                                    <Formik
                                        initialValues={{
                                            pwd: '',
                                            pwdr: ''
                                        }}
                                        validate={values => {
                                            const errors = {};
                                            if (!values.pwd) {
                                                errors.pwd = "Lozinka je obavezna."
                                            } else if (values.pwd.length < 6) {
                                                errors.pwd = "Lozinka mora da ima više od 6 karaktera."
                                            }

                                            if (!values.pwdr) {
                                                errors.pwdr = "Ponovljena lozinka je obavezna."
                                            } else if (values.pwdr !== values.pwd) {
                                                errors.pwdr = "Ponovljena lozinka se ne poklapa."
                                            }
                                            return errors;
                                        }}
                                        onSubmit={this.handleClick}
                                    >
                                        <Form>
                                            <div className="form-group form-float">
                                                <div className="form-line">
                                                    <label className="form-label">Nova lozinka</label>
                                                    <Field id="pwd" name="pwd" className="form-control" type="password" />
                                                    <ErrorMessage name="pwd" component="div" className="ybxred" />
                                                </div>
                                            </div>
                                            <div className="form-group form-float">
                                                <div className="form-line">
                                                    <label className="form-label">Ponovite lozinku</label>
                                                    <Field id="pwdr" name="pwdr" className="form-control" type="password" />
                                                    <ErrorMessage name="pwdr" component="div" className="ybxred" />
                                                </div>
                                            </div>
                                            <div className="form-group form-float text-center pt-3">
                                                <button type="submit" className="ybxBtn">Promeni lozinku</button>
                                            </div>
                                            <div className="form-group form-float text-center pt-3">
                                                <small><Link to="/login" className="ybxred">Povratak na početnu</Link></small>
                                            </div>
                                        </Form>

                                    </Formik>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </section></main>
            {/* <PublicFooter hasPadding={true} /> */}
        </LayoutFrame>
    }
};



export default ResetPassword;