import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';

const CommentItem = ({ commentsList }) => {

    const comments = commentsList.map((commentItem) =>

        <div className="row" key={commentItem.id}>
            <div className="col-sm-2 col-md-1 col-lg-1 offset-sm-0 offset-lg-0"><img className="rounded-circle" src={'https://youbox-prod-stack-users-store.s3.eu-central-1.amazonaws.com' + commentItem.image_url} /></div>
            <div className="col-sm-10 col-md-11 col-lg-11 col-xl-11 offset-lg-0 offset-xl-0 align-self-center">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 offset-sm-0 offset-lg-0">
                        <h6 className="comment-h6">{commentItem.fullname}</h6>
                        <p className="comment-p">{commentItem.content}</p>
                        {/* <div className="row">
                            <div className="col"><button className="btn btn-primary chat-btn-like" type="button"><i className="fa fa-thumbs-up"></i>{commentItem.likes}</button><button className="btn btn-primary chat-btn-like m-1" type="button"><i className="fa fa-thumbs-down"></i>{commentItem.dislikes}</button></div>
                        </div> */}
                    </div>
                </div>
                <hr className="comm-hr" />
            </div>
        </div>
    );

    return comments
};


export default CommentItem;