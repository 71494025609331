import React from 'react';
import LayoutFrame from '../feed/Layout';
import LoadingYbx from '../general/Loading';
import PlusItem from './PlusItem';
import ybxClient from '../../apis/ybxClient';
import PublicFooter from '../general/PublicFooter';



class YbxPlus extends React.Component {

    state = { premiumArray: null };
    panes = [];
    selectedFilter = 1

    constructor(props) {
        super(props);
    }


    async componentDidMount() {
        const response = await ybxClient.get('content/premiumContent');
        var premium = response.data.data;
        this.setState({ premiumArray: premium });
    }

    render() {

        const handleSelect = (eventKey) => this.handleClick(eventKey);

        if (this.state.premiumArray == null) {
            return <LayoutFrame fixedBg={true}><main className="page"> <section className="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
        }

        return <LayoutFrame fixedBg={true}>

            <main className="page">
                <section className="clean-block features">
                    <div className="container text-white">
                        <h2 className="text-left ybx-page-title">YouBox +</h2>
                    </div>
                    <section className="clean-block about-us">
                        <div className="container">
                            <div className="row justify-content-center">
                                <PlusItem premiumList={this.state.premiumArray} />
                            </div>
                        </div>
                    </section>
                </section>
            </main>

            <PublicFooter hasPadding={true} />
        </LayoutFrame>
    }
};



export default YbxPlus;