import React from 'react';
import PublicFooter from '../general/PublicFooter';
import LayoutFrame from '../feed/Layout';


const YbxCookies = () => {
    return (<LayoutFrame fixedBg={true}>
            <main className="page" style={{ 'background': 'white'}}>
                <section className="clean-block features">
                    <div className="container">
                        <div className="block-heading" style={{ 'paddingTop': '40px' }}>
                            <h2 className="text-black">Politika kolačića</h2>
                        </div>
                        <div className="row justify-content-center">
                            <p>Kolačići su male tekstualne zbirke podataka (datoteke) koji se prilikom posete internet strani šalju na Vaš računar ili mobilni uređaj i kojima se kasnije može pristupiti. Zahvaljujući kolačićima vidi se šta interesuje pojedine posetioce internet strane, što pomaže da se poboljša upotreba za sve korisnike. </p>
                            <p>Koristimo kolačiće neophodne za funkcionisanje internet strane, odnosno one koji omogućavaju da pregledate naše stranice, kolačiće za statistiku posećenosti i unapređenje performansi internet strane i kolačiće za oglašavanje. Kolačići na internet strani se takođe koriste za pamćenje sesije korisnika sajta. Kolačić je internog tipa, kriptovan je i podatak ne može biti iskorišćen eksterno.</p>
                            <p>Vi imate mogućnost da izaberete da li ćete prihvatiti kolačiće koji nisu neophodni za funkcionisanje naše internet stranice. Mi bismo svakako cenili Vašu odluku da prihvatite sve kolačiće jer nam time pomažete da internet stranicu uskladimo sa interesovanjima posetilaca. Takođe, možete i naknadno da odbijete korišćenje kolačića izmenom postavki u Vašem pretraživaču. Moguće je da ukoliko to uradite nećete moći da koristite sve funkcije ove internet strane.</p>
                            <p>Naša internet strana koristi Google analitiku (Google Analytics), uslugu za veb analitiku koju pruža kompanija Google. Google analitika koristi kolačiće, odnosno tekstualne zbirke podataka koje postavlja na Vaš uređaj, da bismo lakše analizirali kako korisnici upotrebljavaju internet stranu.</p>
                            <p>Ukoliko su Vam potrebne dodatne informacije o kolačićima Google analitike, pogledajte stranice Google koje se odnose na pružanje pomoći i politiku zaštite privatnosti: <a href="https://policies.google.com/privacy?hl=en" target="_blank" className="ybxred">Politika zaštite privatnosti Google-a</a> i <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank" className="ybxred" >Pomoć Google analitike.</a></p>
                        </div>
                    </div>
                </section>
            </main>
            <PublicFooter hasPadding={false} />
        </LayoutFrame>
    );

};

export default YbxCookies;