import React from 'react';
import PublicFooter from '../general/PublicFooter';
import LayoutFrame from '../feed/Layout';

const TocPage = () => {
    return (
        <LayoutFrame fixedBg={true}>

            <main className="page" style={{ 'background': 'white'}}>
                <section className="clean-block features">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="block-heading" style={{ 'paddingTop': '100px'}}>
                                    <h2 style={{'color':'black'}}>Politika privatnosti</h2>
                                </div>
                                <div className="terms-of-service-nav">
                                    <p>Datum: 01.01 2020.</p>
                                    <p>Politika privatnosti ima za cilj da vas informiše koje podatke prikupljamo o vama,
                                        kako koristimo te podatke kao i da vam pruži ostale bitne informacije za ostvarivanje
                                        vaših prava u vezi sa zaštitom podataka o ličnosti u skladu sa Zakonom o zaštiti podataka o
                                        ličnosti (ZZPL) i Opštom uredbom o zaštiti podataka Evropske unije (GDPR).</p>
                                    <ul className="list-unstyled">
                                        <li>
                                            <a href="#paragraph_1" style={{'color':'black'}}>O POLITICI PRIVATNOSTI</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_2" style={{'color':'black'}}>PODACI O RUKOVAOCU VAŠIH LIČNIH PODATAKA</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_3" style={{'color':'black'}}>LIČNI PODACI KOJE OBRAĐUJEMO</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_4" style={{'color':'black'}}>SVRHA I PRAVNI OSNOV OBRADE</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_5" style={{'color':'black'}}>KOME OTKRIVAMO VAŠE LIČNE PODATKE?</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_6" style={{'color':'black'}}>PRIKUPLJANJE LIČNIH PODATAKA OD DECE</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_7" style={{'color':'black'}}>MESTO ČUVANJA I BEZBEDNOST VAŠIH LIČNIH PODATAKA</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_8" style={{'color':'black'}}>KOLIKO ČUVAMO VAŠE PODATKE</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_9" style={{'color':'black'}}>VAŠA PRAVA</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_10" style={{'color':'black'}}>VEZA SA DRUGIM INTERNET STRANAMA</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_11" style={{'color':'black'}}>POLITIKA KOLAČIĆA</a>
                                        </li>
                                        <li>
                                            <a href="#paragraph_12" style={{'color':'black'}}>KONTAKT</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="terms-of-service-content">
                                    <h5 id="paragraph_1">O POLITICI PRIVATNOSTI</h5>
                                    <p>
                                        Politika privatnosti ima za cilj da vas informiše koje lične podatke prikupljamo,
                                        kako koristimo te podatke kao i da vam pruži ostale bitne informacije za ostvarenje vaših
                                        prava u vezi sa zaštitom podataka o ličnosti. Naša Politika privatnosti definiše odnos prema podacima
                                        o ličnosti koje posedujemo u cilju poštovanja prava na privatnost koje je zagarantovano međunarodnim i domaćim propisima.
                                        Ovom Politikom privatnosti vas obaveštavamo o tome ko obrađuje vaše lične podatke
                                        odnosno ko je rukovalac vaših ličnih podataka, o svrsi obrade i pravnom osnovu obrade, načinu obrade,
                                        vrstama podataka o ličnosti koje obrađujemo, primaocima kojima se podaci o ličnosti otkrivaju, roku čuvanja podataka,
                                        vašim pravima, prenosu vaših podataka u druge države i bezbednosnim merama koje primenjujemo.
                                    </p>
                                    <h5 id="paragraph_2">PODACI O RUKOVAOCU VAŠIH LIČNIH PODATAKA</h5>
                                    <p>
                                        Odgovoran za prikupljanje i obradu podataka o ličnosti je privredno
                                        društvo YouBox doo, iz Beograda, Cara Dušana 62, matični broj 21447650, pib
                                        111241353 (u daljem tekstu „YouBox“, „mi“, „nas“ ili „naš“).
                                    </p>
                                    <h5 id="paragraph_3">LIČNI PODACI KOJE OBRAĐUJEMO</h5>
                                    <p>
                                        Obrađujemo vaše lične podatke koje ste nam dali
                                        prilikom prijave na našoj internet stranici odnosno aplikaciji ili koje možemo
                                        automatski prikupiti kada koristite našu uslugu. Lični podaci koji su označeni
                                        kao obavezni ili se automatski prikupljaju prilikom korišćenja usluge, obrađujemo
                                        u cilju izvršenja ugovora o pružanju naših usluga, poštovanja zakona i legitimnog
                                        interesa da unapredimo našu uslugu i da vam pružimo najbolji kvalitet usluge.
                                        Vaše lične podatke koji nisu obavezni za izvršenje ugovora obrađujemo isključivo
                                        na osnovu vašeg pristanka.
                                    </p>
                                    <ol>
                                        <li>
                                            (PODACI O NALOGU) - Korisničko ime, e-mejl, lozinka,
                                            ime i prezime, datum rođenja, jezik, fotografija, broj mobilnog telefona,
                                            država boravišta, podatak o vrsti korisnika naloga, podatak o popunjavanju ankete o
                                            pruženim uslugama, podaci iz komunikacije sa korisničkom službom.
                                        </li>
                                        <li>
                                            (PODACI O PRETPLATI) - Datum, iznos i valuta plaćanja, podatak o istoriji plaćanja, bankarski ID broj korisnika.
                                        </li>
                                        <li>
                                            (PODACI O KORIŠĆENJU) - Postavke u okviru aplikacije, podaci o pretragama i
                                            istorija pregleda, podaci o sviđanju, kreiranim video listama, komentari korisnika,
                                            podaci o praćenju korisnika od stane drugih korisnika, vreme i trajanje pregleda.
                                        </li>
                                        <li>
                                            (TEHNIČKI PODACI) - Marka-tip uređaja sa koga se koristi usluga,
                                            operativni sistem uređaja, MAC i IMEI broj uređaja, lokacija, IP adresa,
                                            podatak o internet vezi i pružaocu internet veze, podatak o verziji aplikacije,
                                            podatak o rezoluciji ekrana uređaja, podatak o vremenskoj zoni uređaja, podatak o kretanju kroz aplikaciju.
                                        </li>
                                    </ol>

                                    <h5 id="paragraph_4">SVRHA I PRAVNI OSNOV OBRADE</h5>
                                    <h6><strong>Pružanje besplatne usluge strimovanja</strong></h6>
                                    <p>U svrhu pružanja usluge strimovanja video materijala,
                                        nuđenja video materija u skladu sa vašim interesovanjem, mogućnosti da
                                        prilagodite podešavanja vašem interesovanju, obezbeđivanja integriteta i raspoloživosti video sadržaja,
                                        zaštite autorskih prava, pružanja informacija odnosno obrada vaših zahteva u vezi sa pravima i obavezama
                                        iz ugovora, dobijanja personalizovanih poruka i postavljanja komentara, praćenja drugih korisnika,
                                        informisanja o novim uslugama i funkcionalnosti aplikacije i emitovanju reklama trećih lica, vaše lične
                                        podatke (podaci o nalogu, korišćenju i tehnički podaci) obrađujemo u cilju izvršenja ugovora o pružanju
                                        naše usluge Uslovi korišćenja</p>
                                    <p>Napominjemo da je davanje tačnih i potpunih podataka o ličnosti uslov za zaključenje ugovora, u suprotnom nećemo moći da vam pružimo našu uslugu.</p>

                                    <h6><strong>Pružanje Premium usluge strimovanja</strong></h6>
                                    <p>U svrhu pružanja Premium usluge strimovanja video materijala, a koja uključuje posebne pogodnosti uključujući mogućnost isključivanja prikazivanja reklamnog sadržaja, obrađujemo i vaše podatke o pretplati u cilju izvršenja ugovora i poštovanja zakonskih obaveza.</p>

                                    <h6><strong>Unapređenje rada platforme</strong></h6>
                                    <p>Kako bismo unapredili performanse naše platforme, razvili nove usluge i unapredili sadržaj, vaše lične podatke koje se odnose na analizu korišćenja usluge (podaci o korišćenju i tehnički podaci) obrađujemo na osnovu našeg legitimnog interesa da pružimo kvalitetniju i sadržajniju uslugu.</p>

                                    <h6><strong>Prijava za najnovije vesti i obaveštenja</strong></h6>
                                    <p>U svrhu obaveštenja putem o budućim događajima, koncertima i novim video sadržajima, vaš e-mejl obrađujemo na osnovu vašeg pristanka. Pristanak koje ste dali u ovu svrhu u svakom trenutku možete opozvati, a mi ćemo u tom slučaju vašu e-mejl adresu obrisati.</p>


                                    <h5 id="paragraph_5">KOME OTKRIVAMO VAŠE LIČNE PODATKE?</h5>
                                    <p>U cilju pružanja naših usluga i ispunjavanja obaveza, vaše lične podatke radi dalje obrade činimo dostupnim:</p>
                                    <ul>
                                        <li>
                                            Pružaocu hosting usluga
                                        </li>
                                        <li>
                                            Pružaocu usluga administriranja baze podataka i tehničke podrške
                                        </li>
                                        <li>
                                            Pružaocu usluga održavanja i razvoja aplikacije
                                        </li>
                                        <li>
                                            Banci ili drugom pravnom licu koje pruža usluge plaćanja i to samo neophodne podatke za ostvarenje svrhe obrade
                                        </li>
                                    </ul>
                                    <p>YOUBOX, u svojstvu rukovaoca vaših podataka, ima zaključene ugovore o obradi podataka o ličnosti sa gore navedenim pravnim licima koja su obrađivači ili podobrađivači podataka o ličnosti. S tim u vezi, njihova prava i obaveze u vezi sa obradom vaših ličnih podataka su u potpunosti uređena.</p>
                                    <p>Vaše lične podatke možemo otkriti i drugim licima ili organima vlasti kada verujemo da je to neophodno da bismo zaštitili vaša, naša ili prava drugih lica, i to samo uz odgovarajući pravni osnov.</p>

                                    <h5 id="paragraph_6">PRIKUPLJANJE LIČNIH PODATAKA OD DECE</h5>
                                    <p>Mi ne prikupljamo lične podatke lica mlađih od 15 godina osim ukoliko nisu dati uz pristanak roditelja.
                                        U slučaju da utvrdimo da obrađujemo podatke lica mlađih od 15 godina bez pristanka roditelja, nalog će biti obrisan.
                                        Ukoliko smatrate da obrađujemo lične podatke deteta mlađeg od 15 godina, obratite nam se i mi ćemo ih obrisati.
                                    </p>

                                    <h5 id="paragraph_7">MESTO ČUVANJA I BEZBEDNOST VAŠIH LIČNIH PODATAKA</h5>
                                    <p>Bezbednost Vaših ličnih podataka je za nas veoma važna. Zbog toga biramo obrađivače koji primenjuju adekvatne tehničke, organizacione i kadrovske mere kako bismo otklonili rizike od slučajnog ili nezakonitog uništenja, gubitka, izmene, neovlašćenog otkrivanja podataka o ličnosti ili neovlašćenog pristupa podacima o ličnosti.
                                        Naši obrađivači održavaju optimalan nivo bezbednosti i poverljivosti ličnih podataka najsavremenijom arhitekturom servisa, uzimajući u obzir raspoloživost, integritet i sigurnost ličnih podataka. To se postiže korišćenjem različitih tehnologija, kao što su upotreba lozinke, šifrovanje, ograničavanje i evidentiranje pristupa ličnim podacima, upotrebom zaštitnog zida (firewall), zaštitom od prestanka servisa (DDoS), kreiranjem rezervne kopije podataka, zaštitom od virusa i spam-a, upotrebom HTTPS protokola, redundantnim sistemom za čuvanje podataka, kontinualanim nadzorom sistema i servisa, kao i ostalim tehnologijama korišćenim za omogućavanje bezbednog korišćenja servisa.
                                        Vaše lične podatke obrađujemo u Republici Srbiji, Republici Irskoj, Saveznoj Republici Nemačkoj, Ujedinjenom Kraljevstvu Velike Britanije i Severne Irske i Sjedinjenim Američkim Državama. Ove države se nalaze na listi država koje obezbeđuju primereni nivo zaštite podataka o ličnosti u skladu sa Odlukom o Listi država, delova njihovih teritorija ili jednog ili više sektora određenih delatnosti u tim državama i međunarodnih organizacija u kojima se smatra da je obezbeđen primereni nivo zaštite podataka o ličnosti "Službeni glasnik RS", br. 55/19.
                                        Ipak, prenos podataka preko interneta nikada ne može biti potpuno siguran i pored svih preduzetih mera bezbednosti. Iako sledimo opšteprihvaćene sigurnosne standarde da bismo zaštitili lične podatke koje su nam dostavljene, nije moguće garantovati sigurnost ili integritet informacija na internetu. Zbog toga Vam preporučujemo da preduzmete mere zaštite kako biste zaštitili svoje podatke, uključujući zaštitu lozinki, korišćenje bezbednih mreža za pristup internetu i odjavu iz mrežnih usluga kada ste izvan svog uređaja. S tim u vezi, rizik zloupotrebe podataka snosi i davalac podataka.
                                    </p>

                                    <h5 id="paragraph_8">KOLIKO ČUVAMO VAŠE PODATKE</h5>
                                    <p>Vaše lične podatke, podatke o nalogu, korišćenju i tehničke podatke brišemo nakon 3 godine od proteka jednogodišnje neaktivnosti naloga, a podatke o istoriji pregleda čuvamo 90 dana.</p>

                                    <p>Podatke o pretplati, kao i vaše lične podatke u vezi sa pretplatom, čuvamo 10 godina u skladu sa zakonom.</p>
                                    <p>Vašu e-mejl adresu koju ste nam dali radi slanja najnovijih vesti i obaveštenja brišemo nakon vaše odjave.</p>

                                    <h5 id="paragraph_9">VAŠA PRAVA</h5>
                                    <p>U cilju zaštite vašeg prava na privatnost obaveštavamo vas da imate pravo da nam se obratite putem e-mejla i da od nas tražite uvid u vaše lične podatke, ispravku ili brisanje vaših ličnih podataka. Takođe, imate pravo da tražite da se ograniči obrada vaših ličnih podataka, pravo da povučete pristanak na obradu, pravo da nam uložite prigovor na obradu kao i da podnesete pritužbu Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti ili drugom nadzornom organu.</p>

                                    <p>YOUBOX bi cenio napor da nam se obratite pre podnošenja pritužbe Povereniku ili drugom nadzornom organu, kako bismo probali da otklonimo problem.</p>


                                    <h5 id="paragraph_10">VEZA SA DRUGIM INTERNET STRANAMA</h5>
                                    <p>Naša internet platforma sadrži vezu ka drugim internet stranicama. Te internet stranice mi ne kontrolišemo i ovo obaveštenje o privatnosti se ne odnosi na te stranice. Mi nismo odgovorni za obradu podataka o ličnosti koju vrše druge internet stranice</p>

                                    <h5 id="paragraph_11">POLITIKA KOLAČIĆA</h5>
                                    <p>O kolačićima koje koristi naša internet stranica možete videti na sledećem linku POLITIKA KOLAČIĆA</p>

                                    <h5 id="paragraph_12">KONTAKT</h5>
                                    <p>Ukoliko imate bilo kakvih pitanja u vezi sa našom Politikom privatnosti, ili ukoliko želite da ostvarite neko od vaših prava, možete nam se obratiti putem e-mejla.</p>

                                    <p>Politika privatnosti može biti izmenjena u skladu sa potrebama i propisima. Svaka izmena ove politike biće objavljena na internet platformi. </p>



                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <PublicFooter hasPadding={false} />
        </LayoutFrame>
    );

};

export default TocPage;