
const LoginUserService = {
    isSignedIn: function (value) {
        //inspect the value
        const stateStorage = localStorage.getItem('ybxState');
        const userToken = JSON.parse(stateStorage);
        if (userToken == null) {
            return false;
        }
        return userToken.isSignedIn === true ? true : false;
    },

    getUserToken: () => {
        let token;
        try {
            const stateStorage = localStorage.getItem('ybxState');
            const stateObj = JSON.parse(stateStorage);
            if (stateObj == null) {
                token = false;
            }else{
                token = stateObj.token;
            }

        } catch (e) {
            console.error(e);
        }

        if (token && token.length > 0){
            return token;
        }else{
            return null;
        }
        
    },

    getUserAndPhoto: function (value) {
        //inspect the value
        const stateStorage = localStorage.getItem('ybxState');
        const stateObj = JSON.parse(stateStorage);
        if (stateObj == null) {
            return false;
        }

        return {
            img: stateObj.userImg,
            username: stateObj.username
        }
    }


};

export default LoginUserService;