import axios from 'axios';
import LoginUserService from '../components/auth/useToken';

// const loggedIn = LoginUserService.isSignedIn();


class ServiceApi {

  constructor() {
    let service = axios.create({
      baseURL: 'https://ybx-api.taptech.rs',
      headers: {
        Authorization:
          'Bearer ' + LoginUserService.getUserToken()
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {

    if (error.config && error.config["x-ybx-autoredirect-on-error"] == false){
      console.log("No-auto-redirect-for-error");
    }else{
    switch (error.response.status) {
      case 401:
        this.redirectTo(document, '/')
        break;
      case 404:
        this.redirectTo(document, '/404')
        break;
      default:
        this.redirectTo(document, '/500')
        break;
    }
  }
    return Promise.reject(error)
  }

  redirectTo = (document, path) => {
    document.location = path
  }

  get(path, callback) {
    return this.service.get(path).then(
      (response) => callback(response.status, response.data)
    );
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  async addOrRemoveFromFavorite(videoId, actionType, callback) {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append('videoId', videoId);
      bodyFormData.append('action', actionType);
      bodyFormData.append('platform', 'web');
      let responsePromise = this.service.request({
        method: 'POST',
        url: "/content/videos/toggle-favorite-video",
        responseType: 'json',
        data: bodyFormData,
        "x-ybx-autoredirect-on-error":false
      }).then((response) => {
        if (callback && response) {
          callback(response.status, response.data);
        }
      });
      // console.log("Promise: ", responsePromise);
      return responsePromise;
    } catch (err) {
      console.error("Greska: ", err);
      return Promise.reject();
    }
  }


  registerNewUser(callback) {

    var bodyFormData = new FormData();
    bodyFormData.append('username', 'nesto');
    bodyFormData.append('email', 'ee111e@nesto.com');
    bodyFormData.append('password', '123');
    bodyFormData.append('password_confirmation', '123');

    return this.service.request({
      method: 'POST',
      url: "/unp/signup",
      responseType: 'json',
      data: bodyFormData
    }).then((response) => callback(response.status, response.data));

  }


};

export default new ServiceApi;