import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik'; // useFormik

// import PublicHeader from '../general/PublicHeader';
import PublicFooter from '../general/PublicFooter';
import LayoutFrame from '../feed/Layout';

class Login extends React.Component {

  state = { setLoading: false };


  handleClick = async (values, { setErrors }) => {


    this.setState({ setLoading: true });

    var bodyFormData = new FormData();
    bodyFormData.append('email', values.email);
    bodyFormData.append('password', values.pwd);

    axios({
      method: "post",
      url: "https://ybx-api.taptech.rs/unp/login",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).catch(function (error) {
      if (error.response) {
        return Promise.resolve({ error });
      }
    }).then((response) => {

      if (response.error != null) {
        this.setState({ setLoading: false });
        setErrors({ pwd: response.error.response.data.message });
      } else {
        var authResponse = response.data

        var tempUser = {
          isSignedIn: true,
          email: authResponse.data.user.email,
          userImg: authResponse.data.user.images[0].url,
          username: authResponse.data.user.username,
          token: authResponse.data.api_token
        };

        this.setState({ setLoading: false });
        localStorage.setItem('ybxState', JSON.stringify(tempUser));
        this.props.history.push("/home");
        this.props.history.go(0);

      }
    });

  }



  render() {
    return (
      <LayoutFrame fixedBg={false}>
        <section className="leadBg lead-section">

          <div className="container pt-4">
            <div className="row">
              <div className="col-md-12 mx-md-auto">
                <div className="mt-5">
                  <div className="row grid ">
                    <div className="col-md-6 p-5">

                      <h1 className='pb-4 pb-5 ybx-title'>Uloguj se</h1>
                      <div className="col-10">
                        <Formik
                          initialValues={{
                            pwd: '',
                            email: ''
                          }}
                          validate={values => {
                            const errors = {};
                            if (!values.email) {
                              errors.email = 'Email je obavezan';
                            } else if (
                              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                              errors.email = 'Email nije validan';
                            }

                            if (values.pwd.length < 2) {
                              errors.pwd = "Sifra mora da ima vise od 2 karaktera."
                            }

                            return errors;
                          }}
                          onSubmit={this.handleClick}
                        >
                          <Form>
                            <div className="form-group form-float">
                              <div className="form-line">
                                <label className="form-label text-white">Email</label>
                                <Field className="form-control" id="email" name="email" type="email" />
                                <ErrorMessage name="email" component="div" className="ybxred" />
                              </div>
                            </div>
                            <div className="form-group form-float">
                              <div className="form-line">
                                <label className="form-label text-white">Lozinka</label>
                                <Field id="pwd" name="pwd" className="form-control" type="password" />
                                <ErrorMessage name="pwd" component="div" className="ybxred" />
                              </div>
                            </div>
                            <div className="form-group form-float pt-4">
                              <div className="form-line">
                                <button type="submit" className="loginBtn">Prijavi se</button>
                              </div>
                            </div>
                          </Form>

                        </Formik>
                      </div>
                      <div className="pt-4">
                        <small><Link to="/forgot" className="text-white">Zaboravljena lozinka?</Link></small>
                      </div>
                    </div>
                    <div className="col-md-6 mt-5">
                      <div className="loginCard">
                        <h1 className="mt-5 font-weight-lighter pt-5">Nemaš YouBox nalog?</h1>
                        <div className="pt-4 mb-5">
                          <p>Registruj se.<br /> Isprati muziku svuda i na svakom mestu.</p>
                        </div>
                        <Link className="ybxBtn" to="/register">Isprobaj, besplatno je</Link>
                      </div>
                    </div>
                    <div className="pt-4 mb-5"></div>
                  </div>
                </div>

              </div>
            </div>
            <div className="see-more text-center">
              <p className="text-uppercase mb-1 mb-xm-3 text-white">SAZNAJ VIŠE O YOUBOX-U</p>
              <span className="fa fa-chevron-down text-secondary bounce p-3"></span>
            </div>
          </div>
        </section>
        <section style={{ 'backgroundColor': 'white' }} className="landing-sec-4 discoverBg">
          <div className="col-md-12 col-lg-6 offset-lg-3" style={{ 'paddingTop': '40px' }}>
            <h1 className="text-center" style={{ 'color': '#6c757d', 'font-weight': 'normal' }}>Pretraži,<br />pronađi i <br />uživaj.</h1>
          </div>
        </section>
        <section  className="clean-block clean-hero landing-sec-5 formBg">
          <div id="next-section" className="col-lg-11">
            <div className="text">
              <h1 className="text-left">Muzika koju voliš. <br />Koncerti uživo gde god <br />da se nalaziš. <br /></h1>
              <p className="text-left">Prati &quot;live&quot; nastupe na svom telefonu, tabletu ili računaru.</p>
            </div>
          </div>
        </section>
        <section className="gradientBg on-youbox">
          <div className="container">
            <div className="row">
              <div className="col-md-6 order-last order-md-first align-self-end">
                <div className="on-youbox-img">
                  <img src="assets/css/assets/iPhone.png" alt="" />
                </div>
              </div>
              <div className="col-md-6 align-self-center">
                <div>
                  <h2 className="text-center text-md-left py-4 ybx-title">Šta se sve nalazi na<br/>YouBox-u?</h2>
                  <div className="d-flex align-items-start justify-content-xm-center justify-content-start justify-content-md-start youbox-offer">
                    {/* <div className="offer-icon bg-secondary text-center">
                      <img src="assets/css/assets/favorite.png" alt="" />
                    </div> */}
                    <div className="offer-text w-75 pl-4 pl-md-3 pl-lg-4 text-white">
                      <h3 className="ybx-title">Live</h3>
                      <p>Besplatni i plaćeni "live" koncerti.</p>
                    </div>
                  </div>
                  <div className="py-lg-3 py-2 d-flex align-items-start justify-content-xm-center justify-content-start justify-content-md-start youbox-offer">
                    {/* <div className="offer-icon bg-secondary text-center">
                      <img src="assets/css/assets/favorite.png" alt="" />
                    </div> */}
                    <div className="offer-text w-75 pl-4 pl-md-3 pl-lg-4 text-white">
                      <h3 className="ybx-title">Muzika & Plejliste</h3>
                      <p>Istraži video snimke različitih muzičkih pravaca i kreiraj plejliste odabirom omiljenih snimaka.</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start justify-content-xm-center justify-content-start justify-content-md-start youbox-offer">
                    {/* <div className="offer-icon bg-secondary text-center">
                      <img src="assets/css/assets/favorite.png" alt="" />
                    </div> */}
                    <div className="offer-text w-75 pl-4 pl-md-3 pl-lg-4 text-white">
                      <h3 className="ybx-title">Nova Izdanja</h3>
                      <p>Ekskluzivne premijere novih muzičkih video spotova</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="clean-block clean-hero landing-sec-6 musicBg">
          <div className="col-lg-11">
            <div className="text">
              <h1 className="text-left">Oseti. <br />Inspiriši. <br />Zvuk.<br /></h1>
              <p className="text-left">Snimi sopstveni video snimak i pošalji ga na YouBox platformu da ga ceo svet vidi!</p>
            </div>
          </div>
        </section>
        <PublicFooter hasPadding={false} />
      </LayoutFrame>
    );
  }
};


export default Login;