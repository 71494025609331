import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';
import LoginUserService from '../auth/useToken';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import ybxClient from '../../apis/ybxClient';
import ReactGA from 'react-ga';
import SearchField from '../search/SearchField';

const PublicHeader = ({ fixedBg }) => {

    const history = useHistory();
    const currentUser = LoginUserService.getUserAndPhoto();
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });
    }, []);

    const logoutCurrentUser = async () => {
        localStorage.removeItem("ybxState")
        window.location.reload();
    }

    function checkActionBtn() {
        return scroll ? "navbar-collapse justify-content-end showBtn" : "navbar-collapse justify-content-end hideBtn";
    }

    function checkPrivateMenuItems() {
        if (currentUser == false) {
            return <div className={checkActionBtn()}>
                <Link className="btn btn-primary loginBtn" to="/login">Uloguj se</Link>
                <div className="p-1"></div>
                <Link className="btn btn-primary loginBtn" to="/register">Registruj se</Link>
            </div>
        } else {
            return <div className="collapse navbar-collapse text-capitalize" id="navcol-1">
                <ul className="nav navbar-nav mr-auto">
                    <li className="nav-item" role="presentation"></li>
                    <NavLink className="nav-item nav-link" role="presentation" to="/plus">Youbox +</NavLink>
                    <NavLink className="nav-item nav-link" role="presentation" to="/artists">Umetnici</NavLink>
                    <NavLink className="nav-item nav-link" role="presentation" to="/videos">Video snimci</NavLink>
                    <NavLink className="nav-item nav-link" role="presentation" to="/favorites">Omiljeno</NavLink>
                </ul>
                <div className="dropdown">
                    <a className="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">{currentUser.username}&nbsp;<img className="rounded-circle" src={"https://youbox-prod-stack-users-store.s3.eu-central-1.amazonaws.com" + currentUser.img} /></a>
                    <div className="dropdown-menu text-capitalize" role="menu"><Link className="dropdown-item" role="presentation" to="/myProfile">Moj profil</Link>
                        {/* <Link className="dropdown-item" to="/help-main">Pomoć i podrška</Link> */}
                        <a className="dropdown-item" role="presentation" onClick={logoutCurrentUser}>Odjavi se</a></div>
                </div>
            </div>
        }
    }

    function checkSearch() {
        if (currentUser == false) {
            return
        } else {
            return <SearchField history={history} isMobile={false} />
        }
    }


    return (
        <nav className="navbar navbar-light navbar-expand-lg fixed-top clean-navbar">
            <div className="container"><Link className="navbar-brand logo" to="/"><img src="/assets2/img/logo.png" /></Link>
                <div className="navbar-search">
                    {/* <SearchField history={history} isMobile={false} /> */}
                    {checkSearch()}
                </div>
                {/* <NavLink activeclassname="menuActiveBar" to="/mSearch"><button data-toggle="collapse" className="navbar-toggler"><i className="icon ion-ios-search-strong" style={{'color': '#ffffff','font-size': '35px'}}></i></button></NavLink> */}
                <button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1">
                    <span className="sr-only">Toggle navigation</span><i className="icon ion-android-menu"></i></button>
                {checkPrivateMenuItems()}
            </div>
        </nav>
    )
};

export default PublicHeader;