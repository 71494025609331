import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';

const RecommendedVideo = ({ recommendedList }) => {

    const todoItems = recommendedList.map((selectedItem) =>
        <Link key={selectedItem.video_id} to={'/video/' + selectedItem.video_id}
            onClick={onRecommendedVideoClicked} >
            <div className="row p-b-15">
                <div className="col-9 col-sm-5 col-md-4 col-lg-4 col-xl-5 offset-1 offset-sm-0 img-rcmd"><img className="img-fluid" src={'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + selectedItem.images[2].url} /></div>
                <div className="col-9 col-sm-7 col-md-7 col-lg-8 col-xl-7 offset-1 offset-sm-0 offset-lg-0 text-left">
                    <h6 className="text-left text-white">{selectedItem.title}</h6>
                    <p className="text-left text-white-50 text-14">{artistList(selectedItem.creators)}</p>
                </div>
            </div>
        </Link>
    );

    return todoItems
};

function onRecommendedVideoClicked() {
    try {
        let jwPlayer = window.jwplayer;
        if (jwPlayer && typeof jwPlayer == "function") {
            jwPlayer().pause();
        }
    } catch (e) {
        console.error(e);
    }

}

function artistList(creators) {
    var nicknameArray = []
    creators.forEach(element => nicknameArray.push(element.nickname));
    return nicknameArray.join(', ');
}


export default RecommendedVideo;