import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import LoginUserService from './useToken';

const PrivateRoute = ({ component: Component, ...rest }) => {

    const isLoggedIn = LoginUserService.isSignedIn();
    return (                
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (              
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />
    )
}

export default PrivateRoute