import React from 'react';
import LayoutFrame from '../feed/Layout';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga';

class ForgotPassword extends React.Component {


    state = { resetSuccess: false };

    constructor(props) {
        super(props);
        ReactGA.pageview('/forgotpassword');
    }


    handleClick = async (values, { setErrors }) => {

        var bodyFormData = new FormData();
        bodyFormData.append('email', values.email);

        await axios({
            method: "post",
            url: "https://ybx-api.taptech.rs/unp/password-forgot",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).catch(function (error) {
            if (error.response) {
                return Promise.resolve({ error });
            }
        }).then((response) => {
            if (response.error != null) {
                setErrors({ email: response.error.response.data.message });
            } else {
                this.setState({ resetSuccess: true });
            }
        });
    }


    render() {
        // if (this.state.userData == null) {
        //     return <LayoutFrame><LoadingYbx /></LayoutFrame>
        // }

        if (this.state.resetSuccess) {
            return (
                <LayoutFrame fixedBg={true}>
                    <main className="page faq-page">
                        <div className="container">
                            <section className="generalHead">
                                <div className="container">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12">
                                            <div className="error-message text-center">
                                                <div className='about-icon'>
                                                    <img src='/assets/img/modal-check.png' alt="" />
                                                </div>
                                                <h1 className='text-white'>Čestitamo!</h1>
                                                <p className='text-white mb-1'>Uspešno ste poslali zahtev za promenu lozinke. Proverite Vaš email.</p>
                                                <Link to="/login" className="btn btn-secondary text-uppercase">Povratak na početnu</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </main>
                </LayoutFrame>
            );
        }



        return <LayoutFrame fixedBg={true}>

            <main className="page">
                <section className="clean-block features">
                    <div className="container">
                        <div className="block-heading" style={{ 'paddingTop': '100px' }}>
                            <h2>Zaboravljena lozinka</h2>
                            <p className="lead ybx-sub-paragraf">Molimo Vas unesite Vašu email adresu, a mi ćemo Vas kontaktirati.</p>
                        </div>
                        <Row className="justify-content-md-center pt-4">
                            <Col xs={12} md={5} lg={5} >
                                <Formik
                                    initialValues={{
                                        email: ''
                                    }}
                                    validate={values => {
                                        const errors = {};
                                        if (!values.email) {
                                            errors.email = 'Email je obavezan.';
                                        } else if (
                                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                        ) {
                                            errors.email = 'Email nije validan';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={this.handleClick}
                                >
                                    <Form>
                                        <div className="form-group form-float">
                                            <div className="form-line">
                                                <label className="form-label text-white">Email</label>
                                                <Field className="form-control" id="email"
                                                    name="email"
                                                    placeholder="ime@domen.com"
                                                    type="email" />
                                                <ErrorMessage name="email" component="div" className="ybxred" />
                                            </div>
                                        </div>
                                        <div className="form-group form-float text-center pt-3">
                                            <button type="submit" className="ybxBtn">Pošalji zahtev</button>
                                        </div>
                                        <div className="form-group form-float text-center pt-3">
                                            <small><Link to="/login" className="ybxred">Povratak na početnu</Link></small>
                                        </div>
                                    </Form>

                                </Formik>
                            </Col>
                        </Row>

                    </div>
                </section>
            </main>
            {/* <PublicFooter hasPadding={true} /> */}
        </LayoutFrame>
    }
};



export default ForgotPassword;