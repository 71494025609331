import React from 'react';
import LayoutFrame from '../feed/Layout';
import MobileSearchField from './MSearchField';
import { List, XLg, ArrowLeft } from 'react-bootstrap-icons';
import { Card, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';

class MobileSearchPage extends React.Component {

    constructor(props) {
        super(props);
    }

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <section style={{ 'height': '90px', 'background-color': '#000000' }}>
                <div className="container-fluid" style={{ 'height': 'inherit' }}>
                    <div className="row justify-content-center align-items-center" style={{ 'height': 'inherit' }}>
                        <div className="col-2 col-sm-1">
                            <button onClick={() => this.props.history.goBack()}>
                                <i className="fa fa-arrow-left"></i>
                                {/* <ArrowLeft size={24} className="ybxred" /> */}
                            </button>
                        </div>
                        <div className="col-10 col-sm-8">
                            {/* <input type="text" className="search-header-field search-header-field-mobile" placeholder="Pretraži YouBox" /> */}
                            <MobileSearchField history={this.props.history} isMobile={false} />                            
                        </div>
                    </div>
                </div>
            </section>

            // <header className="fixed-top index-header bg-dark" >
            //     <div className="container">
            //     <nav className="navbar navbar-expand-lg navbar-expand-sm navbar-light">
            //             <div className="row">
            //                 <Col>
            //                     <button type="button" className="btn ybxred" onClick={() => this.props.history.goBack()}>
            //                         <ArrowLeft size={24} className="ybxred" /> 
            //                     </button>                                 
            //                 </Col>
            //                 <Col >
            //                     <SearchField history={this.props.history} isMobile={true} />
            //                 </Col>
            //             </div>
            //         </nav>
            //     </div>
            // </header>
        )
    }
};



export default MobileSearchPage;