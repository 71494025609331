import React from 'react';
// import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import PublicHeader from '../general/PublicHeader';
import PublicFooter from '../general/PublicFooter';

const helpPage = () => {
    return (
        <span>
            <PublicHeader fixedBg={false}/>
            <section className="helper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 align-self-center text-center lead-text">
                            <h1 className='text-white pb-3'>How can we help you?</h1>
                            <div className="col-xm-8 col-lg-6 col-xl-5 mx-auto">
                                <div className="desctop-search d-flex justify-content-center">
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="see-more text-center">
                        <a href="#see-more" className="slideSlow">
                            <img className="bounce" src="css/assets/arrow-down.png" alt=""/>
                        </a>
                    </div>
                </div>
            </section>
            <section className="help-info" id="see-more" >
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xm-6 col-lg-3 text-center mb-3 mb-lg-0">
                            <a href="#" className="info d-block h-100">
                                <div className="info-icon mx-auto mb-2">
                                    <img src="css/assets/favorite.png" alt=""/>
                                </div>
                                <h6 className="text-secondary">What is YouBox</h6>
                                <p className="info-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </a>
                        </div>
                        <div className="col-12 col-xm-6 col-lg-3 text-center mb-3 mb-lg-0">
                            <a href="#" className="info d-block h-100">
                                <div className="info-icon mx-auto mb-2">
                                    <img src="css/assets/favorite.png" alt=""/>
                                </div>
                                <h6 className="text-secondary">Using YouBox</h6>
                                <p className="info-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </a>
                        </div>
                        <div className="col-12 col-xm-6 col-lg-3 text-center mb-3 mb-lg-0">
                            <a href="#" className="info d-block h-100">
                                <div className="info-icon mx-auto mb-2">
                                    <img src="css/assets/favorite.png" alt=""/>
                                </div>
                                <h6 className="text-secondary">Accounts & Payments</h6>
                                <p className="info-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </a>
                        </div>
                        <div className="col-12 col-xm-6 col-lg-3 text-center mb-3 mb-lg-0">
                            <a href="#" className="info d-block h-100">
                                <div className="info-icon mx-auto mb-2">
                                    <img src="css/assets/favorite.png" alt=""/>
                                </div>
                                <h6 className="text-secondary">Integrations & Partners</h6>
                                <p className="info-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </a>
                        </div>
                    </div>
                    <div className="request text-center">
                        <h2 className="mb-4">
                            Can't find what you are looking for?
                        </h2>
                        <button className="btn btn-secondary">SUBMIT A REQUEST</button>
                    </div>
                </div>
            </section>
            <PublicFooter hasPadding={false} />
        </span>
    );

};

export default helpPage;