import React from 'react';
import { Link } from 'react-router-dom';
import Media from 'react-media';
import OwlCarousel from "react-owl-carousel";


const HomeArtisti = ({ artistList }) => {

    const artistItems = artistList.map((artist) =>
        <div className="card ybx-clicl-anim" key={artist.artist_id} style={{'paddingTop':'20px'}}>
            <Link to={'/artist/' + artist.artist_id}>
            <div className="col text-center"><img className="rounded img-fluid rounded-circle-feed-artist" src={'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + artist.images[2].url} />
                <div className="row">
                    <div className="col">
                        <h6 className="ybx-item-title-bold" style={{ 'fontSize': '14px;' }}>{artist.nickname}</h6>
                    </div>
                </div>
            </div>
            </Link>
        </div>        
    );

    return (
        <div className="card-group" style={{'paddingTop':'20px'}}>
            <Media queries={{ small: "(max-width: 599px)" }}>
                {matches => (
                    <OwlCarousel className="owl-theme" margin={0} items={matches.small ? 2 : 6} dots={false} nav navText={[
                        "<i className='fa fa-chevron-left'></i>",
                        "<i className='fa fa-chevron-right'></i>"
                    ]}>
                        {artistItems}
                    </OwlCarousel>
                )}
            </Media>
        </div>
    );
};


function VideoNumberFormat(num) {
    if (num === 1 || num === 21 || num === 31 || num === 41 || num === 51 || num === 61) {
        return num + " video"
    } else {
        return num + " videa"
    }
}

export default HomeArtisti;