import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import LoginUserService from '../auth/useToken';
import CookieConsent from "react-cookie-consent";

const PublicFooter = ({ hasPadding }) => {


    const currentUser = LoginUserService.getUserAndPhoto();

    function checkPrivateMenuItems() {
        if (currentUser == false) {
            return <span></span>
        } else {
            return <ul className='list-unstyled'>
                <li><NavLink activeclassname="activebar" to="/plus"> Youbox + </NavLink></li>
                <li><NavLink activeclassname="activebar" to="/artists">Umetnici</NavLink></li>
                <li><NavLink activeclassname="activebar" to="/videos">Video Snimci</NavLink></li>
            </ul>
        }
    }


    return (
        <div style={hasPadding ? { 'paddingTop': '8rem' } : { 'paddingTop': '0' }}>
            <CookieConsent
                location="bottom"
                buttonText="Slažem se"
                cookieName="ybxAcceptCookies"
                buttonClasses="btn btn-primary"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "18px" }}
                expires={120}>
                <div className="container">
                    <div className="row">
                        <div className="col-3 col-sm-3 col-lg-1">
                            <img src="/assets/img/cookie.png" style={{'width':'50px', 'height':'50px'}} />
                        </div>
                        <div className="col-10">
                            Ovaj web sajt koristi kolačiće (eng. Cookies) za neophodno funkcionisanje sajta. Klikom na dugme "Slažem se", slažete se sa korišćenjem kolačića na web stranicama Youbox platforme.
                            <Link to="/cookies" className="ybxred">Više o kolačićima</Link>
                        </div>
                    </div>
                </div>
            </CookieConsent>
            <footer className="page-footer ybx dark">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-12 col-sm-3 col-md-3 col-lg-2 offset-0 offset-sm-0"><img className="ybx-footer-logo" src="/assets/img/logo.png" /></div>
                        <div className="col-sm-3">
                            <ul>
                                <NavLink className="nav-item nav-link" role="presentation" to="/plus">Youbox +</NavLink>
                                <NavLink className="nav-item nav-link" role="presentation" to="/artists">Umetnici</NavLink>
                                <NavLink className="nav-item nav-link" role="presentation" to="/videos">Video snimci</NavLink>
                                <NavLink className="nav-item nav-link" role="presentation" to="/favorites">Moje omiljeno</NavLink>
                            </ul>
                        </div>
                        <div className="col-sm-3">
                            <ul>
                                <NavLink className="nav-item nav-link" role="presentation" to="/about">O nama</NavLink>
                                <NavLink className="nav-item nav-link" role="presentation" to="/download">Preuzmi aplikaciju</NavLink>
                                <NavLink className="nav-item nav-link" role="presentation" to="/terms-of-service">Pravila i uslovi</NavLink>
                                <NavLink className="nav-item nav-link" role="presentation" to="/help">Pomoć</NavLink>
                            </ul>
                        </div>
                        <div className="col-sm-3 col-lg-4 offset-lg-0 text-primary align-self-center"><a href="https://www.facebook.com/YouBoxWorld"><i className="fa fa-facebook-f"></i></a><a href="https://www.instagram.com/youboxworld/"><i className="fa fa-instagram"></i></a>
                            <h6 className="text-white text-footer-white">Preuzmi aplikaciju</h6>
                            <a href="https://apps.apple.com/us/app/youbox/id1453383943?ls=1">
                                <img className="small-download-btn" src="/assets/img/download-apple.png" style={{height:44}}/></a>
                            <a href="https://appgallery7.huawei.com/#/app/C102346595">
                                <img className="small-download-btn" src="/assets/img/download-huawei.png" style={{height:44}}/></a>
                            <a href="https://play.google.com/store/apps/details?id=cubes.youbox">
                                <img className="small-download-btn" src="/assets/img/download-android.png" style={{height:44}}/></a>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright ybx-black">
                    <p>© 2021 YouBox. All rights reserved.</p>
                </div>
            </footer>
            {/* <footer className="bgFooter">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-2">
                            <div className='d-flex justify-content-center justify-content-md-start p-3 p-md-0'>
                                <h1><Link to="/home"><img src="/assets/img/logo.png" alt="" /></Link></h1>
                            </div>
                        </div>
                        <div className="col-md-2 col-4">
                            <ul className='list-unstyled'>
                                <li><NavLink activeclassname="activebar" to="/download">Preuzmi aplikaciju</NavLink></li>
                                <li><NavLink activeclassname="activebar" to="/about">O nama</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-4">
                            <ul className='list-unstyled'>
                                <li><NavLink activeclassname="activebar" to="/terms-of-service">Politika privatnosi</NavLink></li>
                                <li><NavLink activeclassname="activebar" to="/cookies">Politika kolačića</NavLink></li>
                                <li><NavLink activeclassname="activebar" to="/help-main">Pomoć</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-4">
                            {checkPrivateMenuItems()}
                        </div>
                        <div className="col-md-4 col-12 social-div">
                            <div className='social d-flex mb-2 justify-content-center justify-content-md-start'>
                                <a href="https://www.facebook.com/YouBoxWorld" className='fa fa-facebook' target="_blank" ></a>
                                <span className="mx-1"></span>
                                <a href="https://www.instagram.com/youboxworld/" target="_blank" className='fa fa-instagram'></a>
                            </div>
                            <h5 className='text-uppercase text-md-left ybxred'>UPIŠI SE NA NAŠU LISTU</h5>
                            <p className='text-md-left'>Prijavi se za najnovije vesti i obaveštenja</p>
                            <form action="" method="post" className="subscribe-form">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="email" className="form-control" name="email" placeholder="E-mail" aria-label="E-mail" />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary send-button" type="submit">
                                                <img src="/assets/img/white.png" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="error-msg"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <section className="copyright">
                        <p className='text-secondary small m-0 text-center text-white text-md-left'>&copy; 2021 YouBox. All rights reserved.</p>
                    </section>
                </div>
            </footer> */}
        </div>
    );
};

export default PublicFooter;
