import React, { Component, Fragment } from 'react'
import PublicHeader from '../general/PublicHeader'
import Media from 'react-media';
import MobileNav from '../general/MobileNav';



const LayoutFrame = ({ children, fixedBg }) => (    
  //   <div>
  //   <Media queries={{
  //     small: "(max-width: 599px)",
  //     medium: "(min-width: 600px) and (max-width: 1199px)",
  //     large: "(min-width: 1200px)"
  //   }}>
  //     {matches => (
  //       <Fragment>
  //         {matches.small && <div><MobileNav fixedBg={fixedBg} />{children}</div>}
  //         {matches.medium && <div><MobileNav fixedBg={fixedBg} />{children}</div>}
  //         {matches.large && <div><PublicHeader fixedBg={fixedBg} />{children}</div>}
  //       </Fragment>
  //     )}
  //   </Media>
  // </div>
  <div><PublicHeader fixedBg={fixedBg} />{children}</div>
)

export default LayoutFrame;