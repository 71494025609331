import React from 'react';
// import { useState } from 'react'
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
// import PublicHeader from '../general/PublicHeader';
import PublicFooter from '../general/PublicFooter';
import LayoutFrame from '../feed/Layout';

class Register extends React.Component {


    formValidation = values => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Email je obavezan.';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Email nije validan';
        }

        if (!values.username) {
            errors.username = "Korisničko ime je obavezno."
        } else if (values.username.length < 3) {
            errors.username = "Korisničko ime mora da ima više od 3 karaktera."
        }

        if (!values.pwd) {
            errors.pwd = "Lozinka je obavezna."
        } else if (values.pwd.length < 6) {
            errors.pwd = "Lozinka mora da ima više od 6 karaktera."
        }

        if (!values.pwdr) {
            errors.pwdr = "Ponovljena lozinka je obavezna."
        } else if (values.pwdr !== values.pwd) {
            errors.pwdr = "Ponovljena lozinka se ne poklapa."
        }
        return errors;
    }




    handleClick = async (values, { setErrors }) => {
        var bodyFormData = new FormData();
        bodyFormData.append('email', values.email);
        bodyFormData.append('username', values.username);
        bodyFormData.append('password', values.pwd);
        bodyFormData.append('password_confirmation', values.pwdr);

        await axios({
            method: "post",
            url: "https://ybx-api.taptech.rs/unp/signup?autologin=true",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).catch(function (error) {
            if (error.response) {
                return Promise.resolve({ error });
            }
        }).then((response) => {

            if (response.error != null) {
                if (response.error.response.data.code === 230 || response.error.response.data.code === 231) {
                    setErrors({ email: response.error.response.data.message });
                }

                if (response.error.response.data.code === 25) {
                    setErrors({ username: response.error.response.data.message });
                } else {
                    setErrors({ pwd: response.error.response.data.message });
                }

            } else {
                var authResponse = response.data

                var tempUser = {
                    isSignedIn: true,
                    email: authResponse.data.user.email,
                    userImg: authResponse.data.user.images[0].url,
                    username: authResponse.data.user.username,
                    token: authResponse.data.api_token
                };
                localStorage.setItem('ybxState', JSON.stringify(tempUser));
                this.props.history.push("/home");
                this.props.history.go(0);
            }
        });
    }

    render() {
        return (
            <LayoutFrame fixedBg={true}>
                <main className="page formBg">
                    <section className="clean-block features">
                        <div className="container">
                            <div className="block-heading" style={{ 'paddingTop': '50px' }}>
                                <h2>Kreiraj nalog</h2>
                            </div>
                            <div className="col-xl-4 offset-xl-4">
                                <Formik

                                    initialValues={{
                                        username: '',
                                        email: '',
                                        pwd: '',
                                        pwdr: ''
                                    }}

                                    validate={this.formValidation}
                                    onSubmit={this.handleClick}
                                >
                                    <Form>

                                        <div className="form-group form-float  text-white">
                                            <div className="form-line">
                                                <label className="form-label">Korisničko ime</label>
                                                <Field type="username" className="form-control" id="email"
                                                    name="username" />
                                                <ErrorMessage name="username" component="div" className="ybxred" />
                                            </div>
                                        </div>
                                        <div className="form-group form-float text-white">
                                            <div className="form-line">
                                                <label className="form-label  text-white">Email</label>
                                                <Field className="form-control" id="email"
                                                    name="email"
                                                    placeholder="ime@domen.com"
                                                    type="email" />
                                                <ErrorMessage name="email" component="div" className="ybxred" />
                                            </div>
                                        </div>
                                        <div className="form-group form-float text-white">
                                            <div className="form-line">
                                                <label className="form-label  text-white">Lozinka</label>
                                                <Field id="pwd" name="pwd" className="form-control" type="password" />
                                                <ErrorMessage name="pwd" component="div" className="ybxred" />
                                            </div>
                                        </div>
                                        <div className="form-group form-float text-white">
                                            <div className="form-line">
                                                <label className="form-label  text-white">Ponovite lozinku</label>
                                                <Field id="pwdr" name="pwdr" className="form-control" type="password" />
                                                <ErrorMessage name="pwdr" component="div" className="ybxred" />
                                            </div>
                                        </div>
                                        <div className="form-group form-float text-center pt-3">
                                            <button type="submit" className="ybxBtn pl-4 pr-4">Registruj se</button>
                                        </div>
                                    </Form>

                                </Formik>
                            </div>
                            <div className="col-xl-8 offset-xl-2">
                                <p className="text-center small text-white">Da biste postali korisnik naše usluge neophodno je da se prijavite odnosno da nam date vaše lične podatke.
                                    O načinu obrade vaših ličnih podataka možete se informisati na stranici „Politika privatnosti“, a u uslovima korišćenja usluge na stranici   <a href="https://youbox.rs/terms-of-service" className="small text-white">Uslovima korišćenja.</a>.
                                    Napominjemo da je davanje tačnih i potpunih podataka o ličnosti uslov za zaključenje ugovora, u suprotnom nećemo moći da vam pružimo našu uslugu.
                                    Pritiskom na dugme "Registruj se" potvrđujete da ste upoznati sa Politikom privatnosti i da prihvatate Uslove korišćenja usluge.
                                </p>
                            </div>
                            <div className="col-xl-6 offset-xl-3 text-center align-self-center">                                                            
                                <div className="col-12 text-white">
                                    <hr/>
                                    Već ste registrovani?
                                </div>
                                <div className="col-12">
                                    <Link to="/login" style={{'color':'#EA184D'}}>Ulogujte se</Link>
                                </div>
                            </div>
                        </div>



                    </section>


                </main >
                <PublicFooter hasPadding={false} />
            </LayoutFrame >
        );
    }
};

export default Register;