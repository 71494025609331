import React from 'react';
import PublicFooter from '../general/PublicFooter';
import LayoutFrame from '../feed/Layout';

const DownloadAppPage = () => {
    return (
        <LayoutFrame fixedBg={true}>
            <main className="page">
                <section className="clean-block features">
                    <div className="container text-white">
                        <div className="block-heading">
                            <h3 className="ybx-title" style={{ 'paddingTop': '40px' }}>Slušaj muziku koju voliš na pametnom telefonu ili tabletu.</h3>
                            <p className="lead ybx-sub-paragraf">Korišćenje na tvom mobilnom telefonu ili tabletu je besplatno, lako i zabavno.</p>
                        </div>
                        <div className="text-center"><a href="https://apps.apple.com/us/app/youbox/id1453383943?ls=1"><img className="small-download-btn" src="assets/img/download-apple.png" /></a><a href="https://appgallery7.huawei.com/#/app/C102346595"><img className="small-download-btn" src="assets/img/download-huawei.png" /></a><a href="https://play.google.com/store/apps/details?id=cubes.youbox"><img className="small-download-btn" src="assets/img/download-android.png" /></a></div>
                        <div className="text-center ybx-p-t-30 text-white"><img className="mobile-big-img" src="assets/img/download-mobiles.png" /><img className="mobile-small-img" src="assets/img/download-mobile.png" />
                            <h4>Jedan nalog,<br />pristup na svim uređajima.</h4>
                        </div>
                    </div>
                </section>
            </main>
            <PublicFooter hasPadding={false} />
        </LayoutFrame>
    );

};

export default DownloadAppPage;