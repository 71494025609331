import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Tooltip, Image, Row, Col } from 'react-bootstrap';

const PlusItem = ({ premiumList }) => {

    function linkGenerator(selected) {
        if (selected.type == "series") {
            return "/serial";
        } else {
            return "/video/" + selected.videoId;
        }
    }

    const todoItems = premiumList.map((selectedItem) =>
        <div className="col-sm-6 col-lg-3" style={{'padding-bottom':'20px'}} key={selectedItem.id}>
            <Link to={linkGenerator(selectedItem)} >
                <strong className="ybx-pill"  style={{ background: `${selectedItem.bubleColor}`}}> {selectedItem.bubbleText}</strong>
                <div className="card clean-card card-clean text-center"><img className="card-img-top w-100 d-block ybx-item-design" src={selectedItem.coverUrl} /></div>
            </Link>
        </div>
    );

    return todoItems
};

export default PlusItem;






