import React from 'react';
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { Image, Tooltip, Button, OverlayTrigger, Row, Col } from 'react-bootstrap';
import ybxClient from '../../apis/ybxClient';
import LayoutFrame from '../feed/Layout';
import LoadingYbx from '../general/Loading';
import { Heart, HeartFill } from 'react-bootstrap-icons';
//import RecommendedVideo from './RecommendedVideo';
import ReactGA from 'react-ga';
import ServiceApi from '../../apis/ServiceApi';
import PublicFooter from '../general/PublicFooter';
import moment from 'moment';
import CommentItem from '../videos/CommentItem';
import { Link } from 'react-router-dom';
import LoginUserService from '../auth/useToken';


class VideoDetails extends React.Component {

  state = { videoDetails: null, idParam: null, relatedVideo: null, isVideoFavorite: false, newCommentValue: '', legacyPlayer: false }
  currentUser = LoginUserService.getUserAndPhoto();

  constructor(props) {
    super(props);
    this.jwplayer = window.jwplayer;

  }

  updateInputValue(evt) {
    this.setState({
      newCommentValue: evt.target.value
    });
  }

  componentWillReceiveProps(nextProps) {
    const idParam = nextProps.match.params.videoId;
    this.loadData(idParam)
    //window.scrollTo(0, 0)
    ReactGA.pageview('/livestream/' + idParam);
  }

  async componentDidMount() {
    const idParam = this.props.match.params.videoId;
    this.loadData(idParam);
  }

  async removeOrAddToFavorite(actionType) {
    try {
      await ServiceApi.addOrRemoveFromFavorite(this.state.videoDetails.livestream_id,
        actionType, (status, data) => { })
        .then(() => {
          this.setState({ isVideoFavorite: actionType == 'add_video' ? true : false });
        })
        .catch((e) => {
          alert("Doslo je do greske...");
        });

    } catch (err) {
      console.error("Fav Error: ", err);
      return Promise.resolve();
    }
  }

  async loadAllComments() {
    const response = await ybxClient.get('/content/get-comments?commentable_id=' + this.state.videoDetails.livestream_id + '&commentable_type=livestream&platform=web&order_by=replies_count&count=100')
      .catch(function (error) {
        if (error.response) {
          if (error.response.status == 404) {
            return Promise.resolve({ error });
          }
        }
      });

    if (response.error != null) {
      //console.log("doslo do greske prilikom ucitavanja komentara:", response.error);
    } else {
      this.setState({ allComments: response.data.data });
    }
  }

  async addNewComment(event) {


    var bodyFormData = new FormData();
    bodyFormData.append('commentable_id', this.state.videoDetails.livestream_id);
    bodyFormData.append('commentable_type', 'livestream');
    bodyFormData.append('comment_content', this.state.newCommentValue);
    bodyFormData.append('action', 'add_comment');
    bodyFormData.append('platform', "web");

    const response = await ybxClient({
      method: "post",
      url: "https://ybx-api.taptech.rs/content/put-comment",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).catch(function (error) {
      if (error.response) {
        return Promise.resolve({ error });
      }
    }).then((response) => {
      if (response.error != null) {
        //console.log("doslo je do greske prilikom cuvanja komentara:", response.error);
      } else {
        //console.log("uspesno dodat komentar", response);

        this.loadAllComments()
        this.setState({ newCommentValue: "" });

      }
    });
  }

  async loadData(idParam) {
    this.setState({ allComments: [] });
    //console.log("ide na: ", idParam);
    const response = await ybxClient.get('/content/livestream/' + idParam)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status > 399) {
            return Promise.resolve({ error });
          }
        }
      });

    if (response.error != null) {

    } else {
      const videoDetails = response.data.data;
      const relatedVideo = response.data.data.related_videos;

      let jwPlayerAutostart = true;
      if (window && window.location && window.location.href && window.location.href.includes("localhost:")) {
        console.info("JW AutoPlay off u lokalu...");
        jwPlayerAutostart = false;
      }
      

      // const response2 = await ybxClient.get('/content/livestreamHls/' + 899)
        const response2 = await ybxClient.get('/content/livestreamHls/' + idParam)
        .catch(function (error) {          
          if (error.response) {                        
              return Promise.resolve({ error });            
          }
        });

      if (response2.error != null) {
        
        if (response2.error.response.status == 404) {
          this.props.history.push("/home");
          this.props.history.go(0);
        } else {
          this.setState({legacyPlayer:true})
        }


      } else {

        this.jwplayer("videoFrame").setup({
          file: response2.data.hlsUrl,
          "displaytitle": false,
          "displaydescription": false,
          "aspectratio": "16:9",
          "width": "100%",
          "pipIcon": "disabled",
          "title": videoDetails.title,
          "description": videoDetails.description,
          "floating": {
            "dismissible": true
          },
          abouttext: 'Youbox video platform',
          aboutlink: "/about",
          "cast": {}
        }).setConfig({
          "repeat": false,
          "autostart": jwPlayerAutostart,
          "mute": false,
          "volume": 100
        });
      }

      this.setState({ videoDetails: videoDetails, idParam: idParam, relatedVideo: relatedVideo, isVideoFavorite: videoDetails.favorite });
      this.loadAllComments()

    }
  }

  dateConverter(trenutno) {
    return moment(trenutno).format('MMM DD, YYYY');
  }

  showComments() {
    if (this.state.allComments != null || this.state.allComments.length > 0) {
      return <CommentItem commentsList={this.state.allComments} />
    } else {
      return <p className="text-white-50">Za ovaj video nema dodatih komentara.<br /></p>
    }
  }


  checkLivestreamPlayer() {
    //if (this.state.videoDetails.hls_url1 != null || this.state.videoDetails.hls_url1 != "") {
    if (this.state.legacyPlayer == false) {
      return <div id="videoFrame" />
    } else {      
      return <iframe src={"https://player.vimeo.com/video/" + this.state.videoDetails.vimeo_uri + "?h=0ce71f2321&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} width="100%" height="600px" frameBorder="0" allowFullScreen></iframe>
      // return <iframe src="https://player.vimeo.com/video/611572399?h=0ce71f2321&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="600px" frameBorder="0" allowFullScreen></iframe>
    }
  }

  render() {

    if (this.state.videoDetails == null) {
      return <LayoutFrame fixedBg={true}><main className="page"><div><div id="videoFrame" /></div><section className="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
    }

    let pageTitle = "";
    let videoDetailsObj = this.state.videoDetails;

    if (videoDetailsObj) {
      if (this.state.videoDetails.title) {
        pageTitle += this.state.videoDetails.title;
      }

      if (videoDetailsObj.creators && Array.isArray(videoDetailsObj.creators)) {
        for (let creatorObj of videoDetailsObj.creators) {
          if (creatorObj.nickname && creatorObj.nickname.length > 0) {
            pageTitle += (" - " + creatorObj.nickname);
          }
        }
      }
    }

    pageTitle += " - YouBox"


    var creatorsElem = "";
    if (videoDetailsObj.creators && Array.isArray(videoDetailsObj.creators)) {

      const creatorListJsx = videoDetailsObj.creators.map((creatorObj) =>
        <Col key={creatorObj.artist_id}>
          <Link key={creatorObj.artist_id} to={'/artist/' + creatorObj.artist_id}  >
            <div className="row">
              <div className="col-lg-1 offset-lg-0"><img className="rounded-circle" src={creatorObj.image_url ?? ""} /></div>
              <div className="col-lg-9 col-xl-7 offset-xl-0 align-self-center">
                <h4 className="artist-nickname">{creatorObj.nickname}</h4>
              </div>
            </div>
          </Link>
        </Col>

      );

      creatorsElem = <div>{creatorListJsx}</div>
    }



    return <LayoutFrame fixedBg={true}>
      <main className="page" >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
          <meta name="description" content={"YouBox"} />
        </Helmet>

        <section className="clean-block about-us">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-12">

                <div className="col">{this.checkLivestreamPlayer()}</div>


                <div className="row p-t-1">
                  <div className="col-lg-9">
                    <h4 className="text-white">{this.state.videoDetails.title}</h4>
                    <p className="text-white-50">{this.state.videoDetails.views.toLocaleString()}  pregleda  -  {this.dateConverter(this.state.videoDetails.created_at)}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <hr />
                  </div>
                </div>
                <div className="row p-b-15">
                  <div className="col">
                    {creatorsElem}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <hr />
                  </div>
                </div>
                <div className="row p-b-15">
                  <div className="col">
                    <h4 className="ybx-red h-bold">Komentari</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-2 col-sm-2 col-md-1 col-lg-1 offset-md-0 offset-lg-0"><img className="rounded-circle" src={"https://youbox-prod-stack-users-store.s3.eu-central-1.amazonaws.com" + this.currentUser.img} /></div>
                      <div className="col-10 col-sm-8 col-md-9 col-lg-9 col-xl-9 offset-0 offset-md-0 offset-lg-0 offset-xl-0 align-self-center"><textarea className="search-header-field input-comment" placeholder="Napiši komentar ..." spellCheck="false" autoComplete="off" rows="3" minLength="3" maxLength="200" value={this.state.newCommentValue} onChange={evt => this.updateInputValue(evt)}></textarea></div>
                      <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-1 offset-8 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-0 align-self-center"><button className="btn btn-primary send-comment" type="button" onClick={() => this.addNewComment()}>Pošalji<i className="fa fa-send-o"></i></button></div>
                    </div>
                    {/* <div className="row p-b-15 p-t-3"> */}
                    {/* {this.showCommentBtn()} */}
                    {/* </div> */}
                    {this.showComments()}
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-4 col-xl-4">
                <h4 className="text-white">Povezani snimci</h4>
                <RecommendedVideo recommendedList={this.state.relatedVideo} />
              </div> */}
            </div>
          </div>
        </section>

        <PublicFooter hasPadding={true} />
      </main>
    </LayoutFrame>
  }




};

export default withRouter(VideoDetails);