import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Modal, Button, OverlayTrigger, Row, Col, Container } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';
// import { Heart, HeartFill, PlayCircle, BookmarkFill } from 'react-bootstrap-icons';
import { BookmarkFill } from 'react-bootstrap-icons';

import ServiceApi from '../../apis/ServiceApi';

const FavoriteModal = ({ favName, itemId }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const removeFromFav = async () => {
    await ServiceApi.addOrRemoveFromFavorite(itemId, "remove_video",
      (status, data) => {
        setShow(false);
        window.location.reload();
      });
  }

  return (
    <span>
      <Button variant="secondary" size="sm" onClick={handleShow}>
        <BookmarkFill className="ybxred" size={20} /> Ukloni
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Ukloni iz omiljenih</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong className="ybxred">{favName}</strong><br /> će biti obrisan iz tvojih omiljenih?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Odustani
          </Button>
          <Button variant="primary" onClick={removeFromFav}>Potvrdi</Button>
        </Modal.Footer>
      </Modal>
    </span>
  );
};



export default FavoriteModal;