import React from 'react';
import ybxClient from '../../apis/ybxClient';
import Nav from 'react-bootstrap/Nav'

import LayoutFrame from '../feed/Layout';
import LoadingYbx from '../general/Loading';
import VideoItem from './VideoItem';
import PublicFooter from '../general/PublicFooter';
import LoadingErrorInfoComponent from '../general/LoadingErrorInfoComponent';


class VideoList extends React.Component {

    state = { allVideoGroups: null, selectedItems: null, selectedName: 1 };
    panes = [];
    selectedFilter = 1

    handleClick = (e) => {

        this.selectedFilter = e

        var selectedList = []

        if (e == 1) {
            selectedList = this.state.allVideoGroups.new
        } else if (e == 2) {
            selectedList = this.state.allVideoGroups.top
        } else if (e == 3) {
            selectedList = this.state.allVideoGroups.fav
        } else {
            selectedList = this.state.allVideoGroups.youbox
        }

        this.setState({ selectedItems: selectedList, selectedName: this.selectedFilter });

    };



    constructor(props) {
        super(props);
    }


    async componentDidMount() {

        const response = await ybxClient.get('content/videos/all').catch((e) => {
            console.error("Greska pri ucitavanju videa; Error = ", e);
            this.setState({ loadingErrorOccured: true });
        });
        const responseFavorite = await ybxClient.get('content/videos/favorites').catch((e) => {
            console.error("Greska pri ucitavanju omiljenih videa; Error = ", e);
            this.setState({ loadingErrorOccured: true });
        });
        const allVideoGroups = response.data.data;

        if (responseFavorite && responseFavorite.data && responseFavorite.data.data) {
            allVideoGroups.fav = responseFavorite.data.data;
        } else {
            allVideoGroups.fav = [];
        }

        this.setState({ allVideoGroups: allVideoGroups, selectedItems: allVideoGroups.new, selectedName: 1 });
    }

    render() {

        const handleSelect = (eventKey) => this.handleClick(eventKey);

        if (this.state.loadingErrorOccured) {
            return <LayoutFrame fixedBg={true}><LoadingErrorInfoComponent /></LayoutFrame>
        } else if (this.state.allVideoGroups == null) {
            return <LayoutFrame fixedBg={true}><main class="page"> <section class="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
        }

        return <LayoutFrame fixedBg={true}>
            <main class="page">
                <section class="clean-block features">
                    <div class="container text-white">
                        <h2 class="text-left ybx-page-title">Video snimci</h2>
                    </div>
                    <div class="container">
                        <Nav className="nav pt-4" variant="pills" activeKey={this.state.selectedName} onSelect={handleSelect}>
                            <Nav.Item>
                                <Nav.Link eventKey="1" onClick={this.handleClick}>
                                    Novo
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2" title="Top10" onClick={this.handleClick}>
                                    Top 10
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="3" title="Omiljeni" onClick={this.handleClick}>
                                    Omiljeni
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="4" title="Youbox" onClick={this.handleClick}>
                                    Youbox
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div class="container" style={{'paddingTop':'20px'}}>
                        <div class="row justify-content-center">
                            <VideoItem videoList={this.state.selectedItems} />
                        </div>
                    </div>
                </section>
            </main>

            <PublicFooter hasPadding={true} />
        </LayoutFrame>
    }
};



export default VideoList;