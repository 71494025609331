import React from 'react';

const LoadingErrorInfoComponent = () => {

    return (
        <div className="container">
            <section className="generalHead">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-offset-5 pt-5">
                        <div className='about-icon'>
                            <img src="assets/img/x.png" alt="Greska" />
                        </div>
                        <div >
                            <h4>
                                Došlo je do greške :(
                            </h4>
                            <p>
                                Došlo je do greške pri učitavanju.
                            </p>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
};

export default LoadingErrorInfoComponent;
