import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


ReactDOM.render(
  <App />,
  document.querySelector('#root')
);