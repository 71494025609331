import React from 'react';

const EmptyPlaceholder = () => {

    return (
        <div className="row justify-content-center">
            <div className="col-12 col-md-offset-5 pt-5">
                <div className='about-icon'>
                    <img src="assets/img/premium-icon.png" alt="" />
                </div>
                <div >
                    <h4>
                        Trenutno nema omiiljenog sadržaja.
                    </h4>
                    <p>
                        Istražite Youbox platformu i kreirajte svoj omiljeni sadržaj.
                    </p>
                </div>
            </div>
         </div>
    );
};



export default EmptyPlaceholder;