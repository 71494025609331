import React from 'react';
import LayoutFrame from '../feed/Layout';
import LoadingYbx from '../general/Loading';
import { Row, Col, Image } from 'react-bootstrap';
import ybxClient from '../../apis/ybxClient';
import SerialItem from './SerialItem';
import PublicFooter from '../general/PublicFooter';



class YbxSerial extends React.Component {

    state = { serialinfo: null };


    constructor(props) {
        super(props);
    }


    async componentDidMount() {
        const serial = await ybxClient.get('content/id/eff1f703-ebef-4514-8133-0cbbb7f173a0');
        this.setState({ serialinfo: serial.data.data })
    }

    render() {

        if (this.state.serialinfo == null) {
            return <LayoutFrame fixedBg={true}><main className="page"> <section className="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
        }

        return <LayoutFrame fixedBg={true}>
            <main className="page">
                <div>
                    <div>
                        <div className="container"><img className="ybx-cover-img" src="https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com/serijali/hurricane/hurricane-cover-mobile.png" />
                            <div className="col-lg-12 offset-lg-0 ybx-cover-title">
                                <div className="row">
                                    <div className="col-lg-12 text-left">
                                        <h2>{this.state.serialinfo.title}</h2>
                                        <p>{this.state.serialinfo.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="clean-block features">
                    <div className="container text-white">
                        <div className="block-heading">
                            <section>
                                <div className="row no-gutters">
                                    <SerialItem serialList={this.state.serialinfo.episodes} />
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </main>
            <PublicFooter hasPadding={true} />
        </LayoutFrame>
    }
};



export default YbxSerial;