import React from 'react';
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { Image, Tooltip, Button, OverlayTrigger, Row, Col } from 'react-bootstrap';
import ybxClient from '../../apis/ybxClient';
import LayoutFrame from '../feed/Layout';
import LoadingYbx from '../general/Loading';
import { Heart, HeartFill } from 'react-bootstrap-icons';
import RecommendedVideo from './RecommendedVideo';
import ReactGA from 'react-ga';
import ServiceApi from '../../apis/ServiceApi';
import PublicFooter from '../general/PublicFooter';
import moment from 'moment';
import CommentItem from './CommentItem';
import { Link } from 'react-router-dom';
import LoginUserService from '../auth/useToken';


class VideoDetails extends React.Component {

  state = { videoDetails: null, idParam: null, relatedVideo: null, isVideoFavorite: false, newCommentValue: '' }
  currentUser = LoginUserService.getUserAndPhoto();

  constructor(props) {
    super(props);
    this.jwplayer = window.jwplayer;

  }

  updateInputValue(evt) {
    this.setState({
      newCommentValue: evt.target.value
    });
  }

  componentWillReceiveProps(nextProps) {
    const idParam = nextProps.match.params.videoId;
    this.loadData(idParam)
    //window.scrollTo(0, 0)
    ReactGA.pageview('/video/' + idParam);
  }



  async componentDidMount() {
    const idParam = this.props.match.params.videoId;
    this.loadData(idParam);
  }

  async removeOrAddToFavorite(actionType) {
    try {
      await ServiceApi.addOrRemoveFromFavorite(this.state.videoDetails.video_id,
        actionType, (status, data) => { console.log("Gotovo dodavanje"); })
        .then(() => {
          this.setState({ isVideoFavorite: actionType == 'add_video' ? true : false });
        })
        .catch((e) => {
          alert("Doslo je do greske...");
        });

    } catch (err) {
      console.error("Fav Error: ", err);
      return Promise.resolve();
    }
  }

  async loadAllComments() {
    const response = await ybxClient.get('/content/get-comments?commentable_id=' + this.state.videoDetails.video_id + '&commentable_type=video&platform=web&order_by=replies_count&count=100')
      .catch(function (error) {
        if (error.response) {
          if (error.response.status == 404) {
            return Promise.resolve({ error });
          }
        }
      });

    if (response.error != null) {
      console.log("doslo do greske prilikom ucitavanja komentara:", response.error);
    } else {
      this.setState({ allComments: response.data.data });
    }
  }

  async addNewComment(event) {


    var bodyFormData = new FormData();
    bodyFormData.append('commentable_id', this.state.videoDetails.video_id);
    bodyFormData.append('commentable_type', 'video');
    bodyFormData.append('comment_content', this.state.newCommentValue);
    bodyFormData.append('action', 'add_comment');
    bodyFormData.append('platform', "web");

    const response = await ybxClient({
      method: "post",
      url: "https://ybx-api.taptech.rs/content/put-comment",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).catch(function (error) {
      if (error.response) {
        return Promise.resolve({ error });
      }
    }).then((response) => {
      if (response.error != null) {
        console.log("doslo je do greske prilikom cuvanja komentara:", response.error);
      } else {
        console.log("uspesno dodat komentar", response);

        this.setState({ newCommentValue: "" });
        this.loadAllComments()
      }
    });
  }

  async loadData(idParam) {
    this.setState({ allComments: [], isLoading: true });
   
    const response = await ybxClient.get('/content/video/' + idParam)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status == 404) {
            return Promise.resolve({ error });
          }
        }
      });

    if (response.error != null) {

    } else {
      const videoDetails = response.data.data.video;
      const relatedVideo = response.data.data.related_videos;

      let jwPlayerAutostart = true;
      if (window && window.location && window.location.href && window.location.href.includes("localhost:")) {
        console.info("JW AutoPlay off u lokalu...");
        jwPlayerAutostart = false;
      }

      this.jwplayer("videoFrame").setup({
        file: videoDetails.vimeo_hls_url,
        "displaytitle": false,
        "displaydescription": false,
        "aspectratio": "16:9",
        "width": "100%",
        "pipIcon": "disabled",
        "title": videoDetails.title,
        "description": videoDetails.description,
        "floating": {
          "dismissible": true
        },
        "abouttext": 'Youbox video platform',
        "aboutlink": "/about",
        "cast": {}
      }).setConfig({
        "repeat": false,
        "autostart": jwPlayerAutostart,
        "mute": false,
        "volume": 100
      });

      //this.jwplayer().setVolume(94)

      // this.jwplayer().on('volume', function(e) {
        // alert("Volume is changed to: "+ e.volume);
      // });

      

      // this.jwplayer().once('ready', function(e) {
      //   console.log("spremno ready");              
      // });
      
      // this.jwplayer().once('viewable', function(e) {
      //   console.log("viewable");              
      // });

      // this.jwplayer().once('setupError', function(e) {
      //   console.log("setupError",e);              
      // });

      // this.jwplayer().on('error', function(e) {
      //   console.log("error:",e);      
      // });

      // this.jwplayer().on('buffer', function(e) {
      //   console.log("buffer...",e);      
      // });
      

      // this.jwplayer().on('play', function(e) {
      //   console.log("play ",e);      
      // });

      // this.jwplayer().on('firstFrame', function(e) {
      //   console.log("firstFrame",e);      
      // });

      // this.jwplayer().on('displayClick', function(e) {
      //   console.log("displayClick",e);      
      // });


      // this.jwplayer().on('beforePlay', function(e) {
      //   console.log("displayClick",e);      
      // });

      // this.jwplayer().on('cast', function(e) {
      //   console.log("cast:",e);      
      // });

      // this.jwplayer().on('fullscreen', function(e) {
      //   alert("fullscreen");
      // });


      this.setState({ videoDetails: videoDetails, idParam: idParam, relatedVideo: relatedVideo, isVideoFavorite: videoDetails.favorite });

    }
  }

  dateConverter(trenutno) {
    return moment(trenutno).format('MMM DD, YYYY');
  }

  showComments() {
    if (this.state.allComments != null) {
      return <CommentItem commentsList={this.state.allComments} />
    }
  }

  showCommentBtn() {
    if (this.state.allComments.length < 1) {
      if (this.state.videoDetails.number_of_comments > 0) {
        return <div className="col"><button className="btn btn-primary chat-btn-like" type="button" onClick={() => this.loadAllComments()}><i className="fa fa-comments-o"></i>Prikaži komentare ({this.state.videoDetails.number_of_comments})</button></div>
      } else {
        return <p className="text-white-50">Za ovaj video nema dodatih komentara.<br /></p>
      }
    }
  }

  render() {

    if (this.state.videoDetails == null) {
      return <LayoutFrame fixedBg={true}><main className="page"><div><div id="videoFrame" /></div><section className="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
    }

    let pageTitle = "";
    let videoDetailsObj = this.state.videoDetails;

    if (videoDetailsObj) {
      if (this.state.videoDetails.title) {
        pageTitle += this.state.videoDetails.title;
      }

      if (videoDetailsObj.creators && Array.isArray(videoDetailsObj.creators)) {
        for (let creatorObj of videoDetailsObj.creators) {
          if (creatorObj.nickname && creatorObj.nickname.length > 0) {
            pageTitle += (" - " + creatorObj.nickname);
          }
        }
      }
    }

    pageTitle += " - YouBox"


    var creatorsElem = "";
    if (videoDetailsObj.creators && Array.isArray(videoDetailsObj.creators)) {

      const creatorListJsx = videoDetailsObj.creators.map((creatorObj) =>
        <Col key={creatorObj.artist_id}>
          <Link key={creatorObj.artist_id} to={'/artist/' + creatorObj.artist_id}  >
            <div className="row">
              <div className="col-lg-1 offset-lg-0"><img className="rounded-circle" src={creatorObj.image_url ?? ""} /></div>
              <div className="col-lg-9 col-xl-7 offset-xl-0 align-self-center">
                <h4 className="artist-nickname">{creatorObj.nickname}</h4>
              </div>
            </div>
          </Link>
        </Col>

      );

      creatorsElem = <div>{creatorListJsx}</div>
    }



    return <LayoutFrame fixedBg={true}>
      <main className="page" >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
          <meta name="description" content={"YouBox"} />
        </Helmet>

        <section className="clean-block about-us">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8 col-xl-8">
                <div><div id="videoFrame" /></div>
                <div className="row p-t-1">
                  <div className="col-lg-9">
                    <h4 className="text-white">{this.state.videoDetails.title}</h4>
                    <p className="text-white-50">{this.state.videoDetails.views.toLocaleString()}  pregleda  -  {this.dateConverter(this.state.videoDetails.created_at)}</p>
                  </div>
                  <div className="col offset-xl-0"><button className="btn btn-secondary btn-sm text-center" type="button">Dodaj u omiljene</button></div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <hr />
                  </div>
                </div>
                <div className="row p-b-15">
                  <div className="col">
                    {creatorsElem}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <hr />
                  </div>
                </div>
                <div className="row p-b-15">
                  <div className="col">
                    <h4 className="ybx-red h-bold">Komentari</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-2 col-sm-2 col-md-1 col-lg-1 offset-md-0 offset-lg-0"><img className="rounded-circle" src={"https://youbox-prod-stack-users-store.s3.eu-central-1.amazonaws.com" + this.currentUser.img} /></div>
                      <div className="col-10 col-sm-8 col-md-9 col-lg-9 col-xl-9 offset-0 offset-md-0 offset-lg-0 offset-xl-0 align-self-center"><textarea className="search-header-field input-comment" placeholder="Napiši komentar ..." spellCheck="false" autoComplete="off" rows="3" minLength="3" maxLength="200" value={this.state.newCommentValue} onChange={evt => this.updateInputValue(evt)}></textarea></div>
                      <div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-1 offset-8 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-0 align-self-center"><button className="btn btn-primary send-comment" type="button" onClick={() => this.addNewComment()}>Pošalji<i className="fa fa-send-o"></i></button></div>
                    </div>
                    <div className="row p-b-15 p-t-3">
                      {this.showCommentBtn()}
                    </div>
                    {this.showComments()}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-xl-4">
                <h4 className="text-white">Povezani snimci</h4>
                <RecommendedVideo recommendedList={this.state.relatedVideo} />
              </div>
            </div>
          </div>
        </section>

        <PublicFooter hasPadding={true} />
      </main>
    </LayoutFrame>
  }




};

export default withRouter(VideoDetails);