import React from 'react'
import LayoutFrame from '../feed/Layout';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (<LayoutFrame fixedBg={true}>
        <main className="page faq-page">
            <section className="clean-block features ">
                <div className="container">
                    <div className="block-heading " style={{ 'paddingTop': '100px' }}>
                        <div className="error-message text-center">
                            <h1 className='text-white'>Oops,</h1>
                            <p className='text-white mb-1'>Something went wrong.<br />Error 404. Stranica nije pronađena.</p>
                            <Link to="/login" className="ybxBtn"><img className="mr-2" src="assets/img/arrow-left.png" alt="" />Povratak na početnu</Link>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </LayoutFrame>

    )
}

export default PageNotFound