import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import LayoutFrame from '../feed/Layout';
import ybxClient from '../../apis/ybxClient';
import PublicFooter from '../general/PublicFooter';
import LoadingYbx from '../general/Loading';
import HomeArtisti from './HomeArtist';
import HomeVideo from './HomeVideo';
import { Helmet } from "react-helmet";
import moment from 'moment';

class Home extends React.Component {

    state = { feedItems: null };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        const response = await ybxClient.get('content/home?platform=web');
        const allFeedLists = response.data.data;

        this.setState({ feedItems: allFeedLists });
    }

    dateConverter(trenutno) {
        return moment(trenutno).format('MMM DD, YYYY');
    }


    checkLivestream() {
        // if (this.state.feedItems.livestream != null && this.state.feedItems.livestream.livestream_id != null) {
        //     return <div className="col-sm-8 col-lg-8 col-xl-6 offset-lg-2 offset-xl-3">
        //         <div className="container bgFeed2 ybx-clicl-anim">
        //             <Link className="a-noclick" to={'/livestream/' + this.state.feedItems.livestream.livestream_id} >

        //                 <div className="row">
        //                     <div className="col-sm-12 col-lg-4 col-xl-4">
        //                         <img className="img-fluid" src={'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + this.state.feedItems.livestream.images[0].url} />
        //                     </div>
        //                     <div className="col-lg-8 col-xl-8">
        //                         <div className="row">
        //                             <div className="col-lg-12 col-xl-12">
        //                                 <h5 className="ybx-item-title-bold">{this.state.feedItems.livestream.title}</h5>
        //                             </div>
        //                         </div>
        //                         <div className="row">
        //                             <div className="col">
        //                                 <h5>{this.state.feedItems.livestream.creators[0].nickname}</h5>
        //                             </div>
        //                         </div>
        //                         <div className="row">
        //                             <div className="col">
        //                                 <h6>{this.dateConverter(this.state.feedItems.livestream.event_start)}</h6>
        //                             </div>
        //                         </div>
        //                         <div className="row">
        //                             <div className="col"><i className="fa fa-play-circle" style={{ 'color': '#ffffff', 'fontSize': '50px' }}></i></div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </Link>
        //         </div>
        //     </div >
        // } else {
        //     return this.checkUpcommingEvent()
        // }

        return this.hardcodedHeaderVideoPromo()
    }


    hardcodedHeaderVideoPromo() {
        return <div className="col-sm-8 col-lg-8 col-xl-6 offset-lg-2 offset-xl-3">
            <div className="container bgFeed2 ybx-clicl-anim">
                <Link className="a-noclick" to={'/video/888'} >
                    <div className="row">
                        <div className="col-sm-12 col-lg-4 col-xl-4">
                            <img className="img-fluid" src={'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com/video/888/NR+kaver+za+YT.png'} />
                        </div>
                        <div className="col-lg-8 col-xl-8">
                            <div className="row">
                                <div className="col-lg-12 col-xl-12">
                                    <h5 className="ybx-item-title-bold">Nikola Rokvić - Besmrtna pesma</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h5>NOVO</h5>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col">
                                    <h6>start</h6>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col"><i className="fa fa-play-circle" style={{ 'color': '#ffffff', 'fontSize': '50px' }}></i></div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div >
    }


    checkUpcommingEvent() {
        if (this.state.feedItems.upcoming_livestream != null && this.state.feedItems.upcoming_livestream.livestream_id != null) {
            return <div className="col-sm-8 col-lg-8 col-xl-6 offset-lg-2 offset-xl-3">
                <div className="container bgFeed2 ybx-clicl-anim">
                    <Link className="a-noclick" to={'/livestream/' + this.state.feedItems.upcoming_livestream.livestream_id} >
                        <div className="row">
                            <div className="col-sm-12 col-lg-4 col-xl-4">
                                <img className="img-fluid" src={'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + this.state.feedItems.upcoming_livestream.images[0].url} />
                            </div>
                            <div className="col-lg-8 col-xl-8">
                                <div className="row">
                                    <div className="col-lg-12 col-xl-12">
                                        <h5 className="ybx-item-title-bold">{this.state.feedItems.upcoming_livestream.title}</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h5>{this.state.feedItems.upcoming_livestream.creators[0].nickname}</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <h6>{this.dateConverter(this.state.feedItems.upcoming_livestream.event_start)}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col"><i className="fa fa-play-circle" style={{ 'color': '#ffffff', 'fontSize': '50px' }}></i></div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div >

        }
    }


    render() {

        if (this.state.feedItems == null) {
            return <LayoutFrame fixedBg={true}><main className="page"> <section className="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
        }

        return (

            <LayoutFrame fixedBg={true}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{"YouBox"}</title>
                    <meta name="description" content={"YouBox"} />
                </Helmet>

                <main className="page">
                    <section className="clean-block features">

                        {this.checkLivestream()}

                        <div className="container bg-feed-grad-red">
                            <h3 className="text-left ybx-page-title-feed">Preporučene pesme</h3>
                            <div className="row justify-content-center">
                                <div className="col-lg-11 offset-lg-0">
                                    <HomeVideo videoList={this.state.feedItems.random_videos} />
                                </div>
                            </div>
                            <h3 className="text-left ybx-page-title-feed">Preporučeni izvođači</h3>
                            <div className="row justify-content-center">
                                <div className="col">
                                    <HomeArtisti artistList={this.state.feedItems.youbox_artists} />
                                </div>
                            </div>
                        </div>

                        <div className="container bgFeed2">
                            <h3 className="text-left ybx-page-title-feed">Novo</h3>
                            <div className="row justify-content-center">
                                <div className="col-lg-11 offset-lg-0">
                                    <HomeVideo videoList={this.state.feedItems.new_videos} />
                                </div>
                            </div>
                            <h3 className="text-left ybx-page-title-feed">Novi izvođači</h3>
                            <div className="row justify-content-center">
                                <div className="col">
                                    <HomeArtisti artistList={this.state.feedItems.new_artists} />
                                </div>
                            </div>
                        </div>

                        <div className="container bgFeed2">
                            <h3 className="text-left ybx-page-title-feed">Top 10 pesama</h3>
                            <div className="row justify-content-center">
                                <div className="col-lg-11 offset-lg-0">
                                    <HomeVideo videoList={this.state.feedItems.top_videos} />
                                </div>
                            </div>
                            <h3 className="text-left ybx-page-title-feed">Top 10 izvođača</h3>
                            <div className="row justify-content-center">
                                <div className="col">
                                    <HomeArtisti artistList={this.state.feedItems.top_artists} />
                                </div>
                            </div>
                        </div>

                        <div className="container bgFeed2">
                            <h3 className="text-left ybx-page-title-feed">YouBox pesme</h3>
                            <div className="row justify-content-center">
                                <div className="col-lg-11 offset-lg-0">
                                    <HomeVideo videoList={this.state.feedItems.youbox_videos} />
                                </div>
                            </div>
                            <h3 className="text-left ybx-page-title-feed">YouBox izvođači</h3>
                            <div className="row justify-content-center">
                                <div className="col">
                                    <HomeArtisti artistList={this.state.feedItems.youbox_artists} />
                                </div>
                            </div>
                        </div>

                    </section>
                </main>


                <PublicFooter />
            </LayoutFrame >);
    }
};

export default Home;