import React from 'react';
import { withRouter } from "react-router";
import ybxClient from '../../apis/ybxClient';
import LayoutFrame from '../feed/Layout';
import VideoItem from '../videos/VideoItem';
import LoadingYbx from '../general/Loading';
import PublicFooter from '../general/PublicFooter';
import ReactGA from 'react-ga';


class ArtistProfile extends React.Component {

    state = { idParam: null, artistInfo: null, artistVideos: null, relatedArtists: null };

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
        const idParam = nextProps.match.params.artistId;
        this.loadData(idParam)
        //window.scrollTo(0, 0)
        ReactGA.pageview('/artist/' + idParam);
    }

    async componentDidMount() {
        const idParam = this.props.match.params.artistId;
        this.loadData(idParam)
    }

    async loadData(idParam) {
        const response = await ybxClient.get('content/artist/' + idParam);
        const artistInfo = response.data.data.artist;
        const artistVideos = response.data.data.videos.all;
        const relatedArtists = response.data.data.related_artists;

        this.setState({ idParam: idParam, artistInfo: artistInfo, artistVideos: artistVideos, relatedArtists: relatedArtists });
    }

     artistPhoto(photoArray) {
        if (photoArray.length < 1) {
            return "/assets/missing-300x300.png";
        } else if (photoArray.length === 3) {
            return 'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + photoArray[2].url;
        } else {
            return 'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + photoArray[0].url;
        }
    }


    render() {

        if (this.state.artistInfo == null) {
            return <LayoutFrame fixedBg={true}><main className="page"> <section className="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
        }

        return <LayoutFrame fixedBg={true}>
            <main className="page">
                <section className="clean-block features">
                    <div className="container text-white bgFeed2">
                        <div className="row">
                            <div className="col-8 col-sm-5 col-md-4 col-lg-4 col-xl-3 offset-xl-0 ">
                                <img className="rounded img-fluid card-img-top w-100 d-block img-artist-cover" style={{ 'margin-top': '10px' }} src={this.artistPhoto(this.state.artistInfo.images)} width="100%" /></div>
                            <div className="col-sm-12 col-md-8 col-lg-8 col-xl-9 offset-xl-0">
                                <h2>{this.state.artistInfo.nickname}</h2>
                                <p style={{ 'fontSize': '0.9rem' }}>{this.state.artistInfo.biography}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="container">
                                <h2>Objave</h2>
                                <div className="row">
                                    <VideoItem videoList={this.state.artistVideos} />
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </main>
            <PublicFooter hasPadding={true} />
        </LayoutFrame>
    }

};

export default withRouter(ArtistProfile);
