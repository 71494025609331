import React from 'react';
import LayoutFrame from '../feed/Layout';
import ybxClient from '../../apis/ybxClient';
import LoadingYbx from '../general/Loading';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Image, Row, Col } from 'react-bootstrap';
import PublicFooter from '../general/PublicFooter';
import moment from 'moment';
import LoginUserService from '../auth/useToken';

const currentUser = LoginUserService.getUserAndPhoto();

class MyProfile extends React.Component {


    state = { userData: null };

    constructor(props) {
        super(props);
    }


    async componentDidMount() {
        const response = await ybxClient.get('unp/user');
        const currentUser = response.data.data.user
        const testDate = moment(currentUser.birthdate).format('YYYY-MM-DD');
        currentUser.birthdate = testDate;
        this.setState({ userData: currentUser });
    }

    handleClick = async (values, { setErrors }) => {

        const newD = moment(values.birthdate).format('DD/MM/YYYY');
        const dateTest = moment(newD, 'DD/MM/YYYY', true);

        var bodyFormData = new FormData();
        bodyFormData.append('username', values.username);
        bodyFormData.append('platform', "web");

        if (dateTest.isValid()) {
            bodyFormData.append('birthdate', newD);
        }

        const response = await ybxClient({
            method: "post",
            url: "https://ybx-api.taptech.rs/unp/profile/edit",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).catch(function (error) {
            if (error.response) {
                return Promise.resolve({ error });
            }
        }).then((response) => {
            if (response.error != null) {

                if (response.error.response.data.code == 234) {
                    setErrors({ birthdate: response.error.response.data.message });
                }
            } else {

                const stateStorage = localStorage.getItem('ybxState');
                var stateObj = JSON.parse(stateStorage);
                stateObj.username = values.username;
                localStorage.setItem('ybxState', JSON.stringify(stateObj));

                this.props.history.push("/home");
                this.props.history.go(0);
            }
        });
    }


    render() {

        if (this.state.userData == null) {
            return <LayoutFrame fixedBg={true}><main className="page"> <section className="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
        }

        return <LayoutFrame fixedBg={true}>

            <main className="page">
                <section className="clean-block features">
                    <div className="container text-white">  
                    <div className="block-heading"></div>                      
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-6 text-center">
                                <div className="row">
                                    <div className="col"><img className="rounded-circle-2" src="https://youbox-prod-stack-users-store.s3.eu-central-1.amazonaws.com/51899/unknown-20210417101520.jpeg" /></div>
                                </div>
                                {/* <div className="row">
                                    <div className="col"><button className="btn btn-primary" type="button">Button</button></div>
                                </div> */}
                            </div>
                            <div className="col-md-6">
                                <h1 className="ybx-title">Moj profil</h1>                                
                                <div className="row">
                                    <div className="col">
                                        <Formik
                                            initialValues={this.state.userData}
                                            validate={values => {
                                                const errors = {};
                                                if (!values.username) {
                                                    errors.username = "Korisničko ime je obavezno."
                                                } else if (values.username.length < 5) {
                                                    errors.username = "Korisničko ime mora da ima više od 3 karaktera."
                                                }
                                                return errors;
                                            }}
                                            onSubmit={this.handleClick}
                                        >
                                            <Form>
                                                <div className="form-group form-float">
                                                    <div className="form-line">
                                                        <label className="form-label">Korisničko ime</label>
                                                        <Field id="username" name="username" className="form-control" type="text" />
                                                        <ErrorMessage name="username" component="div" />
                                                    </div>
                                                </div>
                                                <div className="form-group form-float">
                                                    <div className="form-line">
                                                        <label className="form-label">Email</label>
                                                        <Field className="form-control" id="email" name="email" type="email" />
                                                        <ErrorMessage name="email" component="div" />
                                                    </div>
                                                </div>
                                                <div className="form-group form-float">
                                                    <div className="form-line">
                                                        <label className="form-label">Datum rođenja</label>
                                                        <Field type="date" className="form-control" id="birthdate" name="birthdate" />
                                                        <ErrorMessage name="birthdate" component="div" />
                                                    </div>
                                                </div>

                                                <div className='log-in-button text-center pt-5'>
                                                    <div className="col-12">
                                                        <button type="submit" className="btn btn-primary">Sačuvaj izmene</button>
                                                    </div>
                                                </div>
                                            </Form>

                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* <div className="container">
                <section className="generalHead">
                    <div className="ybxHeader">Moj profil</div>

                    <Row className="justify-content-md-center">
                        <Col sm={3}>
                            <Col xs={8} md={10}>
                                <Image className="avatarBig" src={'https://youbox-prod-stack-users-store.s3.eu-central-1.amazonaws.com' + this.state.userData.images[0].url} roundedCircle />
                            </Col>
                        </Col>
                        <Col sm={6}>
                            <Formik
                                initialValues={this.state.userData}
                                validate={values => {
                                    const errors = {};
                                    if (!values.username) {
                                        errors.username = "Korisničko ime je obavezno."
                                    } else if (values.username.length < 5) {
                                        errors.username = "Korisničko ime mora da ima više od 3 karaktera."
                                    }
                                    return errors;
                                }}
                                onSubmit={this.handleClick}
                            >
                                <Form>
                                    <div className="form-group form-float">
                                        <div className="form-line">
                                            <label className="form-label">Korisničko ime</label>
                                            <Field id="username" name="username" className="form-control" type="text" />
                                            <ErrorMessage name="username" component="div" />
                                        </div>
                                    </div>
                                    <div className="form-group form-float">
                                        <div className="form-line">
                                            <label className="form-label">Email</label>
                                            <Field className="form-control" id="email" name="email" type="email" />
                                            <ErrorMessage name="email" component="div" />
                                        </div>
                                    </div>
                                    <div className="form-group form-float">
                                        <div className="form-line">
                                            <label className="form-label">Datum rođenja</label>
                                            <Field type="date" className="form-control" id="birthdate" name="birthdate" />
                                            <ErrorMessage name="birthdate" component="div" />
                                        </div>
                                    </div>

                                    <div className='log-in-button text-center pt-5'>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary">Sačuvaj izmene</button>
                                        </div>
                                    </div>
                                </Form>

                            </Formik>
                        </Col>
                    </Row>
                </section>
            </div> */}

            <PublicFooter hasPadding={true} />
        </LayoutFrame>
    }
};



export default MyProfile;