import React from 'react';
import ybxClient from '../../apis/ybxClient';
import Nav from 'react-bootstrap/Nav'
import ArtistItem from './ArtistItem';
import LayoutFrame from '../feed/Layout';
import LoadingYbx from '../general/Loading';
import PublicFooter from '../general/PublicFooter';



class ArtistList extends React.Component {

    state = { allVideoGroups: null, selectedItems: null, selectedName: 1 };
    panes = [];
    selectedFilter = 1

    handleClick = (e) => {

        this.selectedFilter = e

        var selectedList = []

        if (e == 1) {
            selectedList = this.state.allVideoGroups.new
        } else if (e == 2) {
            selectedList = this.state.allVideoGroups.top
        } else if (e == 3) {
            selectedList = this.state.allVideoGroups.myFav
        } else {
            selectedList = this.state.allVideoGroups.youbox
        }
        this.setState({ selectedItems: selectedList, selectedName: this.selectedFilter });

    };

    async componentDidMount() {

        const response = await ybxClient.get('content/artists/all');
        const myFavResponse = await ybxClient.get('content/artists/favorites').catch((e) => {
            console.error("Greska pri ucitavanju favorita! Err =", e);
            this.setState({ favLoadingError: true });
        });
        const allVideoGroups = response.data.data;

        if (myFavResponse && myFavResponse.data && myFavResponse.data.data) {
            allVideoGroups.myFav = myFavResponse.data.data;
        } else {
            allVideoGroups.myFav = [];
        }


        this.setState({ allVideoGroups: allVideoGroups, selectedItems: allVideoGroups.new, selectedName: 1 });
    }

    render() {

        const handleSelect = (eventKey) => this.handleClick(eventKey);

        if (this.state.favLoadingError) {
            // console.log("Doslo je do greske pri ucitavanju favorita");
        } if (this.state.allVideoGroups == null) {
            return <LayoutFrame fixedBg={true}><main className="page"> <section className="clean-block features"><LoadingYbx /></section></main></LayoutFrame>
        }

        return <LayoutFrame fixedBg={true}>
            <main className="page">
                <section className="clean-block features">
                    <div className="container text-white">
                        <h2 className="text-left ybx-page-title">Umetnici</h2>
                    </div>
                    <div className="container">
                        <Nav className="nav pt-4" variant="pills" activeKey={this.state.selectedName} onSelect={handleSelect}>
                            <Nav.Item>
                                <Nav.Link eventKey="1" onClick={this.handleClick}>
                                    Novo
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2" title="Top10" onClick={this.handleClick}>
                                    Top 10
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="3" title="Omiljeni" onClick={this.handleClick}>
                                    Omiljeni
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="4" title="Youbox" onClick={this.handleClick}>
                                    Youbox
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="container" style={{'paddingTop':'20px'}}>
                        <div className="row justify-content-center">
                            <ArtistItem artistList={this.state.selectedItems} />
                        </div>
                    </div>
                </section>
            </main>
            <PublicFooter hasPadding={true} />
        </LayoutFrame >
    }
};

export default ArtistList;