import React from 'react';
import { Link } from 'react-router-dom';
// import { Card, Button, OverlayTrigger, Row, Col, Container } from 'react-bootstrap';
import { Button, Col } from 'react-bootstrap';
import { Heart, HeartFill, PlayCircle } from 'react-bootstrap-icons';

import FavoriteModal from './FavoriteModal';

const FavoriteSongs = ({ favoriteArray }) => {

    function artistList(creators) {
        var nicknameArray = []
        creators.forEach(element => nicknameArray.push(element.nickname));
        return nicknameArray.join(', ');
    }

    function artistPhoto(photoArray) {
        if (photoArray.length < 1) {
            return "/assets/missing-300x300.png";
        } else if (photoArray.length === 3) {
            return "https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com" + photoArray[2].url;
        } else {
            return "https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com" + photoArray[0].url;
        }

    }


    const todoItems = favoriteArray.map((selectedItem) =>
        <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" style={{'padding-bottom':'20px'}} key={selectedItem.video_id}>
            <Link to={'/video/' + selectedItem.video_id}>
                <div className="row">
                    <div className="col">
                        <div className="card clean-card"><img className="rounded img-fluid card-img-top w-100 d-block img-artist-cover" src={artistPhoto(selectedItem.images)} /></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="ybx-item-title-bold">{selectedItem.title}</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="ybx-item-subtitle-silver">{artistList(selectedItem.creators)}</h6>
                    </div>
                </div>
            </Link>
            <div className="row">
                <div className="col"><FavoriteModal favName={selectedItem.title} itemId={selectedItem.video_id} /></div>
            </div>
        </div>
    );


    return todoItems
};

export default FavoriteSongs;