import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const VideoItem = ({ videoList, showArtist }) => {

    const videos = videoList.map((video) =>
        <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" style={{'padding-bottom':'20px'}} key={video.video_id}>
            <Link to={'/video/' + video.video_id}>
                <div className="row">
                    <div className="col">
                        <div className="card clean-card"><img className="rounded img-fluid card-img-top w-100 d-block img-artist-cover" src={'https://youbox-prod-stack-ybx-public-store.s3.eu-central-1.amazonaws.com' + video.images[0].url} /></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="ybx-item-title-bold">{video.title}</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="ybx-item-subtitle-silver">{artistList(video.creators)}</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="ybx-item-subtitle-red">{viewsNumberFormat(video.views)}</h6>
                    </div>
                </div>
            </Link>
        </div>        
    );

    return videos;

};

function artistList(creators) {
    var nicknameArray = []
    creators.forEach(element => nicknameArray.push(element.nickname));
    return nicknameArray.join(', ');
}

function viewsNumberFormat(num) {
    if (num == 1 || num == 21 || num == 31 || num == 41 || num == 51 || num == 61) {
        return num.toLocaleString() + " pregled"
    } else {
        return num.toLocaleString() + " pregleda"
    }
}

export default VideoItem;